import type { MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { BaseModel } from '@/src/models/BaseModel';
import type {
  VisibilityConditionsData,
  VisibilityCookieData,
  VisibilityCookieItemData,
  VisibilityDateRangeData,
  VisibilityMetricData,
  VisibilityMetricItemData,
  VisibilityParamData,
  VisibilityParamItemData,
  VisibilityTimeRangeData
} from '@/src/typings/interfaces/data/conditions/visibilityConditions';
import { GameEndingConditionType } from '@/src/typings/interfaces/data/conditions/visibilityConditions';

import { getWithTimezone } from '@/src/utilities/DateHelpers';
import { getQueryParams } from '@/src/utilities/Url';
import { getAllCookies, getCookie } from '@/src/utilities/CookieHelpers';
import { Cache } from '@/src/services/cache';
import useDevice from '@/src/hooks/useDevice';
import useDate from '@/src/hooks/useDate';

interface FormFieldItem {
  field: string;
  type: ConditionType;
  value: string | number;
}

interface FormFieldState {
  enabled?: boolean;
  operator?: Operator;
  items?: FormFieldItem[];
}

interface MetricDataState {
  enabled?: boolean;
  operator?: Operator;
  items?: MetricDataItemState[];
}

interface MetricDataItemState {
  field: string;
  type: ConditionType;
  value: string;
}

interface ParamDataState {
  enabled?: boolean;
  operator?: Operator;
  items?: ParamDataItemState[];
}

interface ParamDataItemState {
  field: string;
  type: ConditionType;
  value: string;
}

interface CookieState {
  enabled?: boolean;
  operator?: Operator;
  items?: CookieItemState[];
}

interface CookieItemState {
  field: string;
  type: ConditionType;
  value: number;
}

interface TimeRangeState {
  enabled?: boolean;
  timeFrom?: number;
  timeTo?: number;
  timeFromRaw?: string;
  timeToRaw?: string;
}

interface DateRangeState {
  enabled?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  dateFromRaw?: string;
  dateToRaw?: string;
}

enum Operator {
  AND = 'and',
  OR = 'or'
}

type DataSet = {
  [key in number | string]: number | string;
};

type DataSetResolver = (id: string | number) => string | boolean | number | undefined;

enum ConditionType {
  EQUAL = 1,
  LARGER_THAN_OR_EQUAL = 2,
  LESS_THAN_OR_EQUAL = 3,
  LARGER_THAN = 4,
  LESS_THAN = 5,
  CONTAINS = 6,
  IS_EMPTY = 7,
  IS_NOT_EMPTY = 8,
  NOT_EQUAL = 9,
  DOESNT_CONTAIN = 10
}

interface Condition {
  field: number | string;
  type: ConditionType;
  value?: number | string;
}

interface BulkPrizesState {
  enabled: boolean;
  condition?: string;
  prizeCondition?: string[];
}

interface CustomItem {
  alias: string;
  settings: unknown;
}

export interface VisibilityConditionsState {
  condition: GameEndingConditionType;
  dateRange?: DateRangeState;
  timeRange?: TimeRangeState;
  metricData?: MetricDataState;
  formFields?: FormFieldState;
  flow?: {
    enabled: boolean;
    pages?: {
      [key: number]: boolean;
    };
  };
  parameters?: ParamDataState;
  cookies?: CookieState;
  bulkPrizes?: BulkPrizesState;
  custom?: CustomItem[];
  devices: {
    desktop: boolean;
    tablet: boolean;
    mobile: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VisibilityConditionsResolver = (data: any) => boolean;

interface VisibilityConditionsResolvers {
  [key: string]: VisibilityConditionsResolver;
}

export class VisibilityConditionsModel extends BaseModel<VisibilityConditionsData, VisibilityConditionsState> {
  parse(data: VisibilityConditionsData) {
    const state = this.state;

    state.condition = data.condition ?? GameEndingConditionType.NONE;

    if (data.date_range) {
      state.dateRange = VisibilityConditionsModel.parseDateRangeData(data.date_range);
    }

    if (data.time_range) {
      state.timeRange = VisibilityConditionsModel.parseTimeRangeData(data.time_range);
    }

    state.devices = {
      desktop: (data.devices?.desktop ?? '1') === '1',
      tablet: (data.devices?.tablet ?? '1') === '1',
      mobile: (data.devices?.mobile ?? '1') === '1'
    };

    if (data.metric_data) {
      state.metricData = VisibilityConditionsModel.parseMetricData(data.metric_data);
      if (data.metric_data?.items) {
        state.metricData.items = data.metric_data.items
          .map<MetricDataItemState>((item: VisibilityMetricItemData) => {
            return {
              field: item.field ?? '',
              type: Number(item.type) as ConditionType,
              value: (item.value || item.value_option || '') + ''
            };
          })
          .filter((item) => item.field);
      } else {
        state.metricData.items = [];
      }
    }

    if (data.form_fields) {
      let operator;

      switch (data.form_fields.operator) {
        case 'and':
          operator = Operator.AND;
          break;

        default:
          operator = Operator.OR;
          break;
      }

      state.formFields = {
        operator,
        enabled: data.form_fields.enabled === '1',
        items:
          data.form_fields?.items
            ?.map<FormFieldItem>((field: FormFieldItem) => {
              return {
                field: field.field,
                type: Number(field.type) as ConditionType,
                value: field.value
              };
            })
            .filter((item) => item.field) ?? []
      };
    }

    if (data?.flow?.pages) {
      state.flow = {
        enabled: data.flow.enabled === '1'
      };

      state.flow.pages = {};
      for (const [flowPageId, isVisible] of Object.entries(data.flow.pages)) {
        if (flowPageId) {
          state.flow.pages[parseInt(flowPageId)] = !!Number(isVisible);
        }
      }
    }

    if (data.parameters) {
      state.parameters = VisibilityConditionsModel.parseParametersData(data.parameters);
      state.parameters.items =
        data.parameters?.items
          ?.map<ParamDataItemState>((item: VisibilityParamItemData) => {
            return {
              field: item.name,
              type: Number(item.type) as ConditionType,
              value: item.value
            };
          })
          .filter((item) => item.field) ?? [];
    }

    if (data.cookies) {
      state.cookies = VisibilityConditionsModel.parseCookiesData(data.cookies);
      state.cookies.items =
        data.cookies?.items
          ?.map<CookieItemState>((item: VisibilityCookieItemData) => {
            return {
              field: item.name,
              type: Number(item.type) as ConditionType,
              value: Number(item.value)
            };
          })
          .filter((item) => item.field) ?? [];
    }

    if (data.bulk_prizes) {
      state.bulkPrizes = {
        enabled: Number(data.bulk_prizes.enabled) === 1,
        condition: data.bulk_prizes?.condition,
        prizeCondition: data.bulk_prizes?.prize_condition ? data.bulk_prizes.prize_condition.map(String) : undefined
      };
    } else if (state.bulkPrizes) {
      delete state.bulkPrizes;
    }

    if (data.custom) {
      const existingCustomState = state.custom ?? [];
      const customState: CustomItem[] = [];

      for (const customAlias in data.custom) {
        if (Object.prototype.hasOwnProperty.call(data.custom, customAlias)) {
          const existingCustomItemState = existingCustomState.find((item) => item.alias === customAlias);

          if (existingCustomItemState) {
            existingCustomItemState.settings = data.custom[`${customAlias}`];
            customState.push(existingCustomItemState);
          } else {
            customState.push({
              alias: customAlias,
              settings: data.custom[`${customAlias}`]
            });
          }
        }
      }

      state.custom = customState;
    } else {
      state.custom = undefined;
    }
  }

  /**
   * @author Dannie Hansen <dannie@leadfamly.com>
   */
  check(metrics?: MetricData, winnerState?: boolean): boolean {
    const campaignStore = useCampaignStore();
    const isEditMode = campaignStore.model?.state.isEditModeActive;

    const visible =
      this.validateCondition(winnerState) &&
      this.validateDateRange() &&
      this.validateTimeRange() &&
      this.validateMetricData(metrics) &&
      this.validateFormFields() &&
      this.validateFlow() &&
      this.validateDevices() &&
      this.validateParameters() &&
      this.validateCookies() &&
      this.validateBulkPrizes() &&
      this.validateCustomResolvers();

    if (isEditMode) {
      return this.validateDevices();
    } else {
      return visible;
    }
  }

  private static parseMetricData(data: VisibilityMetricData) {
    return {
      ...(data?.operator && { operator: data.operator as Operator }),
      ...(data?.enabled && { enabled: data.enabled === '1' })
    };
  }

  private static parseTimeRangeData(data: VisibilityTimeRangeData): TimeRangeState {
    return {
      ...(data?.enabled && { enabled: data.enabled === '1' }),
      ...(data?.time_from && {
        timeFrom: data.time_from
          .split(':')
          .map((t, index) => Number(t) * (index === 0 ? 60 : 1))
          .reduce((a, b) => a + b, 0),
        timeFromRaw: data.time_from
      }),
      ...(data?.time_to && {
        timeTo: data.time_to
          .split(':')
          .map((t, index) => Number(t) * (index === 0 ? 60 : 1))
          .reduce((a, b) => a + b, 0),
        timeToRaw: data.time_to
      })
    };
  }

  private static parseDateRangeData(data: VisibilityDateRangeData): DateRangeState {
    return {
      ...(data?.enabled && { enabled: data.enabled === '1' }),
      ...(data?.date_from && { dateFrom: getWithTimezone(`${data.date_from} 00:00:00`), dateFromRaw: data.date_from }),
      ...(data?.date_to && { dateTo: getWithTimezone(`${data.date_to} 23:59:59`), dateToRaw: data.date_to })
    };
  }

  private static parseCookiesData(data: VisibilityCookieData) {
    return {
      ...(data?.operator && { operator: data.operator as Operator }),
      ...(data?.enabled && { enabled: data.enabled === '1' })
    };
  }

  private static parseParametersData(data: VisibilityParamData) {
    return {
      ...(data?.operator && { operator: data.operator as Operator }),
      ...(data?.enabled && { enabled: data.enabled === '1' })
    };
  }

  private validateCondition(winnerState?: boolean): boolean {
    if (!this.state?.condition || this.state?.condition === GameEndingConditionType.NONE) {
      return true;
    }

    const campaignStore = useCampaignStore();
    const isGameWinner = winnerState ?? campaignStore.gameWinner;

    return (
      (isGameWinner && this.state?.condition === GameEndingConditionType.WINNER) ||
      (isGameWinner === false && this.state?.condition === GameEndingConditionType.LOSER)
    );
  }

  private validateDateRange(): boolean {
    if (!this.state?.dateRange?.enabled) {
      return true;
    }

    const dateFrom = this.state.dateRange?.dateFrom ? this.state.dateRange.dateFrom : null;
    const dateTo = this.state.dateRange?.dateTo ? this.state.dateRange.dateTo : null;
    const todayDate = useDate().getDate();

    if (dateFrom !== null && dateFrom.getTime() > todayDate.getTime()) {
      return false;
    }

    if (dateTo !== null && dateTo.getTime() < todayDate.getTime()) {
      return false;
    }

    return true;
  }

  private validateTimeRange(): boolean {
    if (!this.state?.timeRange?.enabled) {
      return true;
    }

    const today = useDate().getDate();

    if (this.state.dateRange?.enabled) {
      if (this.state.dateRange.dateFromRaw && this.state.timeRange.timeFromRaw) {
        const date = getWithTimezone(`${this.state.dateRange.dateFromRaw} ${this.state.timeRange.timeFromRaw}:00`);

        if (today.getTime() < date.getTime()) {
          return false;
        }
      }

      if (this.state.dateRange.dateToRaw && this.state.timeRange.timeToRaw) {
        const date = getWithTimezone(`${this.state.dateRange.dateToRaw} ${this.state.timeRange.timeToRaw}:59`);

        if (today.getTime() > date.getTime()) {
          return false;
        }
      }
    }

    const timeFrom =
      this.state.timeRange?.timeFrom || this.state.timeRange.timeFrom === 0 ? this.state.timeRange.timeFrom : null;

    const timeTo =
      this.state.timeRange?.timeTo || this.state.timeRange.timeTo === 0 ? this.state.timeRange.timeTo : null;

    const todayTime = today.getHours() * 60 + today.getMinutes();

    if (
      !this.state.dateRange?.enabled &&
      !this.state.dateRange?.dateFromRaw &&
      timeFrom !== null &&
      timeFrom > todayTime
    ) {
      return false;
    }

    if (!this.state.dateRange?.enabled && !this.state.dateRange?.dateToRaw && timeTo !== null && timeTo < todayTime) {
      return false;
    }

    return true;
  }

  private validateMetricData(metrics?: MetricData): boolean {
    if (!this.state?.metricData?.enabled || !this.state?.metricData.items) {
      return true;
    }

    const campaignStore = useCampaignStore();

    return this.matchConditionsAgainstDataSet(
      this.state?.metricData?.operator || Operator.AND,
      metrics ?? campaignStore.metricData,
      this.state?.metricData.items
    );
  }

  private validateFormFields(): boolean {
    if (
      !this.state?.formFields?.enabled ||
      !this.state?.formFields.items ||
      this.state?.formFields?.items.length === 0
    ) {
      return true;
    }

    const campaignStore = useCampaignStore();

    return this.matchConditionsAgainstDataSet(
      this.state?.formFields?.operator || Operator.AND,
      (fieldId) => {
        const model = campaignStore.model?.getRegistrationFieldModel((field) => Number(fieldId) === Number(field.id));

        if (model) {
          // Because model can have all sorts of different values we need to rely on a string value.
          // Here initially we'll just rely on the value that would otherwise have ended up in the cookie.
          // It should be pretty reliant.
          return model.getSerializedCookieValue();
        }

        return undefined;
      },
      this.state?.formFields.items
    );
  }

  private validateFlow(): boolean {
    if (!this.state?.flow?.enabled || !this.state?.flow.pages) {
      return true;
    }

    const campaignStore = useCampaignStore();
    const currentFlowPageId = campaignStore.flowId;

    if (currentFlowPageId === undefined) {
      return false;
    }

    return !!this.state?.flow.pages[Number(currentFlowPageId)];
  }

  private validateParameters(): boolean {
    if (!this.state?.parameters?.enabled || !this.state?.parameters.items) {
      return true;
    }

    return this.matchConditionsAgainstDataSet(
      this.state?.parameters?.operator || Operator.AND,
      getQueryParams(),
      this.state?.parameters.items
    );
  }

  private validateCookies(): boolean {
    if (!this.state?.cookies?.enabled || !this.state?.cookies.items) {
      return true;
    }

    const cookieNames = getAllCookies();
    const cookies: Record<string, string | number> = {};

    cookieNames.forEach((cookie) => {
      if (cookie) {
        const cookieValue = getCookie(cookie);

        if (typeof cookieValue === 'string' || typeof cookieValue === 'number') {
          const currentCookie = getCookie<string | number>(cookie);
          if (currentCookie) {
            cookies[`${cookie}`] = currentCookie;
          }
        }
      }
    });

    return this.matchConditionsAgainstDataSet(
      this.state?.cookies?.operator || Operator.AND,
      cookies,
      this.state?.cookies.items
    );
  }

  private validateBulkPrizes(): boolean {
    if (!this.state?.bulkPrizes?.enabled || !this.state?.bulkPrizes.condition) {
      return true;
    }

    const campaignStore = useCampaignStore();
    const replacementTags = campaignStore.replacementTags;
    const wonBulkPrize = replacementTags.bulk_prize_id;
    const condition = this.state.bulkPrizes.condition;
    const prizeCondition = this.state.bulkPrizes.prizeCondition;

    if (condition === 'winner') {
      if (!wonBulkPrize) {
        return false;
      }

      if (prizeCondition) {
        return prizeCondition.includes(wonBulkPrize.toString());
      }
    } else if (condition === 'not_winner') {
      if (wonBulkPrize) {
        return false;
      }
    } else if (condition.includes('bulk_')) {
      if ('bulk_' + wonBulkPrize !== condition) {
        return false;
      }
    }

    return true;
  }

  /**
   * Validate if item should be shown on device
   */
  private validateDevices(): boolean {
    const { isDesktop, isTablet, isMobile } = useDevice();

    if (!this.state.devices.desktop && isDesktop) {
      return false;
    } else if (!this.state.devices.tablet && isTablet) {
      return false;
    } else if (!this.state.devices.mobile && isMobile) {
      return false;
    }

    return true;
  }

  /**
   * Validate any potential custom resolvers against their registered resolver callback.
   */
  private validateCustomResolvers(): boolean {
    if (this.state.custom && this.state.custom.length > 0) {
      let valid = true;
      const resolvers = VisibilityConditionsModel.getCustomResolvers();

      this.state.custom.forEach((customItem) => {
        if (!resolvers[customItem.alias]) {
          // eslint-disable-next-line no-console
          console.error(`Unrecognized visibility condition resolver "${customItem.alias}"`);
        }

        if (resolvers[customItem.alias] && !resolvers[customItem.alias](customItem.settings)) {
          valid = false;
        }
      });

      return valid;
    }

    return true;
  }

  private matchConditionsAgainstDataSet(
    operator: Operator,
    dataSet: DataSet | DataSetResolver,
    conditions: Condition[]
  ): boolean {
    let allGood = true;
    let okayCount = 0;

    conditions.forEach((condition) => {
      let checkAgainstValue = typeof dataSet === 'function' ? dataSet(condition.field) : dataSet[condition.field];

      let isGroupedCondition = false;

      // Group condition (multiple answers) in metric are put together like prefix and suffix etc. "question_310" + "_answer_1" : 1
      // so we need to check against the value of the field + the value of the answer existing in our dataset (metricData)
      // if it does not exist, we set the value to undefined otherwise we set it to the value of the answer that is always 1
      if (
        typeof condition.field === 'string' &&
        typeof condition.value === 'string' &&
        typeof dataSet === 'object' &&
        (`${condition.field}_${condition.value}` as keyof typeof dataSet) in dataSet
      ) {
        checkAgainstValue = dataSet[`${condition.field}_${condition.value}`];
        isGroupedCondition = true;
      }

      switch (condition.type) {
        case ConditionType.EQUAL:
          if (
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              // eslint-disable-next-line eqeqeq
              condition.value == checkAgainstValue
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.LARGER_THAN_OR_EQUAL:
          if (condition.value === '') {
            allGood = false;
            break;
          }
          if (
            condition &&
            condition.value &&
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              checkAgainstValue !== '' &&
              Number(checkAgainstValue) >= Number(condition.value)
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.LESS_THAN_OR_EQUAL:
          if (condition.value === '') {
            allGood = false;
            break;
          }
          if (
            condition &&
            condition.value &&
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              checkAgainstValue !== '' &&
              Number(checkAgainstValue) <= Number(condition.value)
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.LARGER_THAN:
          if (condition.value === '') {
            allGood = false;
            break;
          }
          if (
            condition &&
            condition.value &&
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              checkAgainstValue !== '' &&
              Number(checkAgainstValue) > Number(condition.value)
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.LESS_THAN:
          if (condition.value === '') {
            allGood = false;
            break;
          }
          if (
            condition &&
            condition.value &&
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              checkAgainstValue !== '' &&
              Number(checkAgainstValue) < Number(condition.value)
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.CONTAINS:
          if (isGroupedCondition && checkAgainstValue) {
            okayCount++;
            break;
          }

          if (
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              (checkAgainstValue + '').toLowerCase().includes((condition.value + '').toLowerCase())
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }

          break;

        case ConditionType.IS_EMPTY:
          if (
            !(
              typeof checkAgainstValue === 'undefined' ||
              checkAgainstValue === null ||
              checkAgainstValue === '' ||
              checkAgainstValue === '0'
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.IS_NOT_EMPTY:
          if (
            !(
              typeof checkAgainstValue !== 'undefined' &&
              checkAgainstValue !== null &&
              checkAgainstValue !== '' &&
              checkAgainstValue !== '0'
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.NOT_EQUAL:
          if (
            !(
              typeof checkAgainstValue === 'undefined' ||
              checkAgainstValue === null ||
              // eslint-disable-next-line eqeqeq
              condition.value != checkAgainstValue
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;

        case ConditionType.DOESNT_CONTAIN:
          if (isGroupedCondition && !checkAgainstValue) {
            okayCount++;
            break;
          }
          if (
            !(
              typeof checkAgainstValue === 'undefined' ||
              checkAgainstValue === null ||
              !(checkAgainstValue + '').toLowerCase().includes((condition.value + '').toLowerCase())
            )
          ) {
            allGood = false;
          } else {
            okayCount++;
          }
          break;
      }
    });

    if (operator === Operator.OR) {
      if (okayCount === 0) {
        return false;
      }
    } else if (!allGood) {
      return false;
    }

    return true;
  }

  /**
   * Get all available custom resolvers.
   * This utilize cache service to be SSR friendly.
   */
  public static getCustomResolvers(): VisibilityConditionsResolvers {
    return Cache.get<VisibilityConditionsResolvers>('visibility-conditions-resolvers') ?? {};
  }

  /**
   * Register a new visibility condition resolver.
   * This utilize cache service to be SSR friendly.
   */
  public static registerCustomResolver<T>(alias: string, fn: (data: T) => boolean): void {
    const resolvers = this.getCustomResolvers();
    resolvers[`${alias}`] = fn;

    Cache.set<VisibilityConditionsResolvers>('visibility-conditions-resolvers', resolvers);
  }

  authorSignature(): string {
    return 'Dannie Hansen';
  }
}
