<template>
  <div
    :id="addon.state.genericAdvancedSettings?.state?.advanced?.elementId"
    ref="addonRef"
    v-click-away="removeActive"
    class="grid__addon"
    :class="addonClasses"
    :style="addonStyles"
    @mouseover.stop="setFocus"
    @mouseleave.stop="removeFocus"
    @click.right="showContextMenu($event, addon, state.edit?.isActive ?? false)"
    @click="toggleActive($event)"
  >
    <component is="style" v-if="state.customCss && isCssEnabled" type="text/css">
      {{ state.customCss.state.code }}
    </component>

    <DeleteElement
      v-if="
        state.edit?.isActive &&
        campaignState?.isEditModeActive &&
        editingStore.activeModel === addon &&
        sectionType === editingStore.activeTabCategory
      "
      :is-green="true"
      :model="addon"
    />

    <component :is="comp" :model="addon" />
    <div v-if="campaignState?.isEditModeActive && !addonValid" class="grid__addon-invalid-message">
      <p-icon :icon="addonNotValidIcon" size="extra-large" />
    </div>
  </div>
</template>

<script lang="ts">
import type { Component, CSSProperties, PropType } from 'vue';
import { computed, defineAsyncComponent, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { AdvancedPositionType, AdvancedWidthType } from '@/src/typings/enums/enums';
import useNavigator from '@/src/hooks/useNavigator';
import DeleteElement from '@/src/components/components/editing/DeleteElement.vue';
import type { ContentAddonType } from '@/src/components/layout/column/ColumnModel';
import ButtonAddon from '@/src/components/addons/lf-button/View.vue';
import TextAddon from '@/src/components/addons/lf-text/View.vue';
import GamePlayAddon from '@/src/components/addons/gameplay/View.vue';
import GameFlowAddon from '@/src/components/addons/gameflow/View.vue';
import ImageAddon from '@/src/components/addons/lf-image/View.vue';
import HeadlineAddon from '@/src/components/addons/lf-headline/View.vue';
import RegistrationAddon from '@/src/components/addons/registration/View.vue';
import type { ClassList } from '@/src/typings/types/types';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';

const addonComponent: { [key: string]: (() => Promise<Component>) | string } = {
  leaderboard: () => import('@/src/components/addons/leaderboard/View.vue'),
  countdown: () => import('@/src/components/addons/countdown/View.vue'),
  'iframe-element': () => import('@/src/components/addons/iframe-element/View.vue'),
  'iframe-games': () => import('@/src/components/addons/iframe-games/View.vue'),
  share: () => import('@/src/components/addons/share/View.vue'),
  rss: () => import('@/src/components/addons/rss/View.vue'),
  accordion: () => import('@/src/components/addons/accordion/View.vue'),
  'lf-divider': () => import('@/src/components/addons/lf-divider/View.vue'),
  'lf-headline': 'addon-lf-headline',
  'lf-text': 'addon-lf-text',
  'lf-image': 'addon-lf-image',
  'lf-animated-headline': () => import('@/src/components/addons/lf-animated-headline/View.vue'),
  'lf-button': 'addon-lf-button',
  'lf-icon': () => import('@/src/components/addons/lf-icon/View.vue'),
  'html-element': () => import('@/src/components/addons/html-element/View.vue'),
  'contact-cta': () => import('@/src/components/addons/contact-cta/View.vue'),
  googlemap: () => import('@/src/components/addons/googlemap/View.vue'),
  'lf-share': () => import('@/src/components/addons/lf-share/View.vue'),
  menu: () => import('@/src/components/addons/menu/View.vue'),
  prizes: () => import('@/src/components/addons/prizes/View.vue'),
  registration: 'addon-registration',
  slider: () => import('@/src/components/addons/slider/View.vue'),
  slideshow: () => import('@/src/components/addons/slideshow/View.vue'),
  sponsors: () => import('@/src/components/addons/sponsors/View.vue'),
  tipafriend: () => import('@/src/components/addons/tipafriend/View.vue'),
  trustpilot: () => import('@/src/components/addons/trustpilot/View.vue'),
  'video-embed': () => import('@/src/components/addons/video-embed/View.vue'),
  stepguide: () => import('@/src/components/addons/stepguide/View.vue'),
  gigya: () => import('@/src/components/addons/gigya/View.vue'),
  'question-validation': () => import('@/src/components/addons/question-validation/View.vue'),
  authentication: () => import('@/src/components/addons/authentication/View.vue'),
  'age-gate': () => import('@/src/components/addons/age-gate/View.vue'),
  gameflow: 'addon-gameflow',
  gameplay: 'addon-gameplay',
  'social-share': () => import('@/src/components/addons/social-share/View.vue'),
  lottie: () => import('@/src/components/addons/lottie/View.vue'),
  'leaderboard-v2': () => import('@/src/components/addons/leaderboard-v2/View.vue')
};

interface Props {
  addon: ContentAddonType;
}

export default defineComponent({
  name: 'AddonWrap',
  components: {
    DeleteElement,
    'addon-lf-headline': HeadlineAddon,
    'addon-lf-text': TextAddon,
    'addon-lf-image': ImageAddon,
    'addon-lf-button': ButtonAddon,
    'addon-gameflow': GameFlowAddon,
    'addon-gameplay': GamePlayAddon,
    'addon-registration': RegistrationAddon
  },
  inheritAttrs: false,
  props: {
    addon: {
      type: Object as PropType<ContentAddonType>,
      required: true
    }
  },
  setup(props: Props) {
    const addonRef = ref<HTMLElement | null>(null);

    const { setFocus, removeFocus } = useNavigator(props.addon);

    const campaignStore = useCampaignStore();
    const campaignModel = campaignStore?.model;
    const campaignState = campaignModel?.state;
    const editingStore = useEditingStore();

    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const shouldAnimateIn = ref(props.addon.state?.genericAdvancedSettings?.state?.transitions?.enabled);

    const animateIn = ref(false);
    const hasAnimatedIn = ref(false);

    const sectionType = computed(() => props.addon.getSection().getSectionType());

    const isCssEnabled = computed(() => {
      return campaignStore.enableCSS;
    });

    const toggleActive = async (event: Event) => {
      if (campaignState?.isEditModeActive && sectionType.value === editingStore.activeTabCategory) {
        event.stopPropagation();

        editingStore.setActiveModel(props.addon);

        await nextTick();

        props.addon.column?.setHasActiveAddon(true);
      }
    };

    const showContextMenu = (event: MouseEvent, model: ContentAddonType, isActive: boolean) => {
      if (campaignState?.isEditModeActive) {
        event.preventDefault();
        event.stopPropagation();

        editingStore.showContextMenu(isActive, event, model);
      }
    };

    const removeActive = () => {
      if (campaignState?.isEditModeActive && sectionType.value === editingStore.activeTabCategory) {
        editingStore.removeActiveModel();
      }
    };

    const addonValid = computed(() => props.addon.isAddonValid());

    const addonClasses = computed(() => {
      const animateClassList: ClassList = {};

      animateClassList[`animated ${props.addon.state.genericAdvancedSettings?.state?.transitions?.type}`] =
        animateIn.value && campaignState?.contentReady && !hasAnimatedIn.value;

      const advancedState = props.addon.state.genericAdvancedSettings?.state;

      return {
        [props.addon.state.classIdentifier ? props.addon.state.classIdentifier : '']: true,
        [`grid__addon--${props.addon.state.alias}`]: true,
        [`grid__addon--${props.addon.state.id}`]: true,
        'grid__addon--edit-focus': props.addon.state.edit?.isFocus && !props.addon.state.edit?.isActive,
        'grid__addon--active-in-navigator':
          props.addon.state.edit?.isActive && editingStore.activeModel === props.addon,
        ...(advancedState?.advanced?.className && {
          [`${advancedState?.advanced?.className}`]: true
        }),
        'grid__addon--invalid': !addonValid.value && campaignState?.isEditModeActive,
        'grid__addon--position-absolute': advancedState?.position?.position === AdvancedPositionType.ABSOLUTE,
        'grid__addon--position-fixed': advancedState?.position?.position === AdvancedPositionType.FIXED,
        ...(advancedState?.generic?.width && {
          'grid__addon--width-full': advancedState?.generic?.width.type === AdvancedWidthType.FULL
        }),
        ...(advancedState?.generic?.width && {
          'grid__addon--width-auto': advancedState?.generic?.width.type === AdvancedWidthType.AUTO
        }),
        ...(advancedState?.generic?.width && {
          'grid__addon--width-custom': advancedState?.generic?.width.type === AdvancedWidthType.CUSTOM
        }),
        ...animateClassList
      };
    });

    const addonStyles = computed<CSSProperties>(() => {
      const addonStyles: CSSProperties = {};
      if (props.addon.state.genericAdvancedSettings?.state?.transitions?.enabled) {
        const enterDuration = props.addon.state.genericAdvancedSettings?.state?.transitions?.duration
          ? props.addon.state.genericAdvancedSettings.state.transitions.duration
          : 525;

        const enterDelay = props.addon.state.genericAdvancedSettings?.state?.transitions?.delay
          ? props.addon.state.genericAdvancedSettings?.state?.transitions.delay
          : 0;

        if (props.addon.state.genericAdvancedSettings?.state?.transitions?.enabled) {
          addonStyles.animationDuration = `${enterDuration}ms`;
          addonStyles.animationDelay = `${enterDelay}ms`;
          addonStyles.transitionDelay = `${enterDelay}ms`;
        }
      }

      if (shouldAnimateIn.value) {
        addonStyles.opacity = 0;
      }

      return {
        ...props.addon.state.genericAdvancedSettings?.state.styling,
        ...addonStyles
      };
    });

    const addonNotValidIcon = computed<string | undefined>(() => {
      if (props.addon.state.alias && campaignState?.edit?.addons) {
        return campaignState.edit.addons[props.addon.state.alias].icon.includes('icon-')
          ? campaignState.edit.addons[props.addon.state.alias].icon
          : campaignState.edit.addons[props.addon.state.alias].icon;
      }

      return undefined;
    });

    let playedEnter = false;

    const onAnimationEnd = (event: AnimationEvent) => {
      if (
        event.target instanceof HTMLElement &&
        props.addon.state.classIdentifier &&
        event.target.classList.contains(props.addon.state.classIdentifier)
      ) {
        addonRef.value?.removeEventListener('animationend', onAnimationEnd);
        hasAnimatedIn.value = true;
      }
    };

    watch(
      () => props.addon.state.genericAdvancedSettings?.state?.transitions,
      (value) => {
        const delay = value?.delay ?? 525;
        const duration = value?.duration ?? 0;
        const totalDuration = delay + duration;

        animateIn.value = true;
        hasAnimatedIn.value = false;

        window.setTimeout(() => {
          animateIn.value = false;
          hasAnimatedIn.value = true;
        }, totalDuration);
      },
      {
        deep: true
      }
    );

    onMounted(() => {
      addonRef.value?.addEventListener('animationend', onAnimationEnd);

      // only section should have InterSectionObserver
      if (shouldAnimateIn.value) {
        // observe if section is in intersection
        if ('IntersectionObserver' in window) {
          const observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting && !playedEnter) {
              if (addonRef.value) {
                if (addonRef.value instanceof HTMLElement) {
                  observer.unobserve(addonRef.value);
                }
              }
              playedEnter = true;

              // Play animation
              animateIn.value = true;
              shouldAnimateIn.value = false;
            }
          });

          if (addonRef.value) {
            // @ts-ignore
            observer.observe(addonRef.value);
          }
        } else {
          animateIn.value = true;
          shouldAnimateIn.value = false;
        }
      }
    });

    watch(
      () => props.addon.state.edit?.isActive,
      (isActive) => {
        if (isActive && campaignState?.isEditModeActive && props.addon.column?.row.section.state.edit) {
          const element = props.addon.column?.row.section.state.edit.sectionRef;
          element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (!isActive) {
          props.addon.column?.setHasActiveAddon(false);
        }
      }
    );

    if (typeof addonComponent[props.addon.state.alias] !== 'function' && readyPromiseResolve) {
      readyPromiseResolve();
    }

    return {
      isCssEnabled,
      sectionType,
      addonRef,
      shouldAnimateIn,
      state: props.addon.state,
      editingStore,
      addonNotValidIcon,
      showContextMenu,
      toggleActive,
      setFocus,
      removeFocus,
      campaignState,
      addonStyles,
      addonValid,
      addonClasses,
      removeActive,
      onBeforeEnter: async () => {
        await readyPromise;

        // Not sure why 2 nextTicks are needed. But they are...
        await new Promise<void>((resolve) => {
          nextTick(resolve);
        });

        await new Promise<void>((resolve) => {
          nextTick(resolve);
        });

        return addonRef.value;
      },
      comp:
        typeof addonComponent[props.addon.state.alias] === 'function'
          ? defineAsyncComponent({
              loader: async () => {
                const asyncLoader = addonComponent[props.addon.state.alias];

                if (typeof asyncLoader !== 'function') {
                  throw new TypeError(`Unrecognized async addon component "${props.addon.state.alias}"`);
                }

                const component = await asyncLoader();

                if (readyPromiseResolve) {
                  readyPromiseResolve();
                }

                return component;
              }
            })
          : addonComponent[props.addon.state.alias]
    };
  }
});
</script>

<style lang="scss" scoped>
.site--editmode-on {
  .grid__addon--active-in-navigator {
    &:hover {
      .element-toolbar--green {
        display: block;
      }
    }
  }

  .grid__addon {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid transparent;
      z-index: 10;
      pointer-events: none;
    }

    .edit-title {
      display: none;
    }

    &--edit-focus {
      &:before {
        border-style: dashed;
        border-color: $edit-border-hover-color;
      }

      & > .edit-title {
        @include lf-title();
        color: #fff;
        background-color: #878787;
      }
    }

    &--active-in-navigator {
      &:before {
        border-color: $edit-active-border-color;
      }

      & > .edit-title {
        @include lf-title();
        color: #000;
        background-color: $edit-active-border-color;
      }
    }
  }
}
</style>
