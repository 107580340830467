<template>
  <div
    :id="model.state?.settings?.state?.advanced?.advanced?.elementId"
    ref="rowRef"
    v-click-away="removeActive"
    class="grid__row row"
    :class="rowClasses"
    :style="rowStyles"
    @mouseover.stop="setFocus"
    @mouseout.stop="removeFocus"
    @click.right="showContextMenu($event, model, model.state.edit?.isActive ?? false)"
    @click="toggleActive($event)"
  >
    <div
      v-if="
        campaignStore.model?.state.isEditModeActive &&
        model.getSection().getSectionType() === editingStore.activeTabCategory
      "
      class="edit-title"
      style="display: none"
    >
      {{ model.state.label }}
    </div>

    <component is="style" v-if="model.state.customCss && isCssEnabled" type="text/css">
      {{ model.state.customCss.state.code }}
    </component>
    <component is="style" v-if="rowCssStyle" type="text/css">
      {{ rowCssStyle }}
    </component>

    <DeleteElement
      v-if="
        model.state.edit?.isActive &&
        campaignStore.model?.state.isEditModeActive &&
        editingStore.activeModel === model &&
        model.getSection().getSectionType() === editingStore.activeTabCategory
      "
      :model="model"
    />
    <div
      v-if="
        model.state.settings.state.style.background?.backgroundType === BackgroundType.SOLID ||
        model.state.settings.state.style.background?.backgroundType === BackgroundType.IMAGE
      "
      class="grid__row-bg-color"
      :style="rowBackgroundStyle"
    ></div>
    <div
      v-if="
        model.state.settings.state.style.background?.backgroundType === BackgroundType.IMAGE &&
        model.state.settings.state.elementStyling.background?.image?.backgroundImage
      "
      class="grid__row-bg"
      :style="rowBackgroundImageStyle"
    ></div>
    <div
      v-if="
        model.state.settings.state.style.overlay?.overlayType === OverlayType.SOLID ||
        model.state.settings.state.style.overlay?.overlayType === OverlayType.GRADIENTS
      "
      class="grid__row-bg-overlay"
      :style="rowBackgroundOverlayStyle"
    ></div>

    <template v-if="!shouldShowRepeatable">
      <Column v-for="column in columns" :key="column.state.modelId" :model="column" />
    </template>

    <template v-else>
      <Column v-for="column in repeatableColumns" :key="column.state.modelId" :model="column" />
    </template>
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import Column from '@/src/components/layout/column/Column.vue';
import type RowModel from '@/src/components/layout/row/RowModel';
import useNavigator from '@/src/hooks/useNavigator';
import DeleteElement from '@/src/components/components/editing/DeleteElement.vue';
import ColumnModel from '@/src/components/layout/column/ColumnModel';
import { getRepeatableData } from '@/src/services/repeatable';
import type { ContentColumnData } from '@/src/typings/interfaces/data/grid/column';
import { BackgroundType, GapType, OverlayType } from '@/src/typings/enums/enums';

import useLayoutSettings from '@/src/components/layout/hooks/useInlineLayoutStyles';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';
import type { RepeatableData } from '@/src/store/repeatable';
import useDevice from '@/src/hooks/useDevice';

export default defineComponent({
  name: 'Row',
  components: {
    DeleteElement,
    Column
  },
  props: {
    model: {
      type: Object as PropType<RowModel>,
      required: true
    }
  },
  setup: function (props) {
    const rowRef = ref<HTMLElement | null>(null);

    const shouldAnimateIn = ref(
      props.model.state.settings.state.advanced?.transitions?.enter &&
        props.model.state.settings.state.advanced.transitions.enter !== 'none'
    );

    const animateIn = ref(false);
    const hasAnimatedIn = ref(false);

    const { setFocus, removeFocus } = useNavigator(props.model);

    const campaignStore = useCampaignStore();
    const editingStore = useEditingStore();

    const isCssEnabled = computed(() => {
      return campaignStore.enableCSS;
    });

    const repeatableColumnMap: Record<string, ColumnModel> = {};

    /**
     * Whether or not our row should display the repeatable content. This checks to ensure that we're not
     * in edit mode, that repeatable is enabled & that we have a repeatable type.
     */
    const shouldShowRepeatable = computed(() => {
      return (
        !campaignStore.model?.state.isEditModeActive &&
        props.model.state.repeatable?.state.enabled &&
        props.model.state.repeatable?.state.type
      );
    });

    /**
     * Computed property that fetches & caches the repeatable data that we should build our columns based on.
     */
    const repeatableData = computed<RepeatableData[]>(() => {
      if (shouldShowRepeatable.value && props.model.state.repeatable?.state.type) {
        return getRepeatableData(`${props.model.section.id}-${props.model.index}`, props.model.state.repeatable);
      }

      return [];
    });

    /**
     * Computed property for caching the repeatable columns shown.
     * This will copy the column data & construct models for each individual column.
     * As well as setting the replacement tags locally available for it.
     */
    const repeatableColumns = computed<ColumnModel[]>(() => {
      if (!shouldShowRepeatable.value) {
        return [];
      }

      return repeatableData.value.map<ColumnModel>((item) => {
        const columnData = JSON.parse(JSON.stringify(props.model.state.columns[0].getData())) as ContentColumnData;

        // Adjust the size of our column to match the grid chosen in the repeatable settings.
        columnData.size = props.model.state.repeatable?.state.columnGrid
          ? props.model.state.repeatable.state.columnGrid
          : 12;

        const id = `repeatable-${String(item.id)}`;
        columnData.id = id;

        const columnModel = repeatableColumnMap[String(id)] ?? new ColumnModel(columnData, props.model);
        columnModel.replacementTags = item;

        repeatableColumnMap[String(id)] = columnModel;

        return columnModel;
      });
    });

    const toggleActive = (event: Event) => {
      if (
        campaignStore.model?.state.isEditModeActive &&
        props.model.getSection().getSectionType() === editingStore.activeTabCategory
      ) {
        event.stopPropagation();

        editingStore.setActiveModel(props.model);
      }
    };

    const showContextMenu = (event: MouseEvent, model: RowModel, isActive: boolean) => {
      if (campaignStore.model?.state.isEditModeActive) {
        event.preventDefault();
        event.stopPropagation();

        editingStore.showContextMenu(isActive, event, model);
      }
    };

    const removeActive = () => {
      if (
        campaignStore.model?.state.isEditModeActive &&
        props.model.getSection().getSectionType() === editingStore.activeTabCategory
      ) {
        editingStore.removeActiveModel();
      }
    };

    const rowClasses = computed(() => {
      const animateClassList: Record<string, boolean> = {};
      if (animateIn.value && campaignStore.model?.state.contentReady && !hasAnimatedIn.value) {
        animateClassList[`animated ${props.model.state?.settings?.state.advanced?.transitions?.enter}`] = true;
      }

      const section = props.model.getSection();

      const alignment = props.model.state.settings.state.basic?.layout?.alignment;
      const width = props.model.state.settings.state.basic?.layout?.width;
      let shouldAddAlignmentClass = false;
      if (typeof width !== 'undefined' && width !== '') {
        shouldAddAlignmentClass = !!alignment;
      }
      const getRowClass = props.model.state.settings.state.advanced?.advanced?.classname ?? '';
      return {
        'grid__row--locked': props.model.state.settings.state.basic?.lockGrid && !useDevice().isDesktop,
        [props.model.state.classIdentifier]: true,
        'no-gutters': section.state.config.settings?.state.basic?.height?.gap === GapType.NO_GUTTER,
        ['grid__row--align-' + alignment]: shouldAddAlignmentClass,
        'row--edit-focus': props.model.state.edit?.isFocus && !props.model.state.edit?.isActive,
        'row--active-in-navigator': props.model.state.edit?.isActive && editingStore.activeModel === props.model,
        [getRowClass]: !!getRowClass,
        ...animateClassList
      };
    });

    const rowAlign = computed(() => {
      const alignment = props.model.state?.settings?.state.basic?.layout?.alignment;
      if (alignment) {
        return;
      }
      return '';
    });

    const rowStyles = computed<CSSProperties>(() => {
      const enterDuration = props.model.state.settings.state.advanced?.transitions?.enterDuration
        ? props.model.state.settings.state.advanced.transitions.enterDuration
        : 525;
      const enterDelay = props.model.state.settings.state.advanced?.transitions?.enterDelay
        ? props.model.state?.settings?.state.advanced.transitions.enterDelay
        : 0;

      let sectionTransition: CSSProperties = {};
      if (props.model.state?.settings?.state.advanced?.transitions?.enter) {
        sectionTransition = {
          animationDuration: enterDuration + 'ms',
          animationDelay: enterDelay + 'ms',
          transitionDelay: enterDelay + 'ms'
        };
      }

      return {
        ...(props.model.state.settings.state.basic?.layout?.width && {
          width: props.model.state.settings.state.basic?.layout?.width
        }),
        ...useLayoutSettings().applyInlineStyles(props.model.state.settings.state.elementStyling.background?.general),
        ...(shouldAnimateIn.value && { opacity: 0 }),
        ...sectionTransition
      };
    });

    const rowCssStyle = computed(() => {
      let css = '';

      if (
        !props.model.state.settings.state.style.content?.useDefaultTextColor &&
        props.model.state.settings.state.style?.content?.color &&
        typeof props.model.state.settings.state.style?.content?.color !== 'undefined'
      ) {
        css = `
          .section .${props.model.state.classIdentifier} .lf-text,
          .section .${props.model.state.classIdentifier} p,
          .section .${props.model.state.classIdentifier} a,
          .section .${props.model.state.classIdentifier} h1,
          .section .${props.model.state.classIdentifier} h2,
          .section .${props.model.state.classIdentifier} h3,
          .section .${props.model.state.classIdentifier} h4,
          .section .${props.model.state.classIdentifier} h5,
          .section .${props.model.state.classIdentifier} h6 {
            color: ${props.model.state.settings.state.elementStyling.background?.general?.color} !important;
          }
        `;
      }
      return css;
    });

    const rowBackgroundStyle = computed(() => {
      return props.model.state.settings.state.elementStyling.background?.color;
    });

    const rowBackgroundImageStyle = computed(() => {
      return props.model.state.settings.state.elementStyling.background?.image;
    });

    const rowBackgroundOverlayStyle = computed(() => {
      if (props.model.state.settings.state.elementStyling.background?.overlay) {
        return props.model.state.settings.state.elementStyling.background?.overlay;
      }
      return {};
    });

    const columns = computed(() => {
      const visibleColumns = props.model.state.columns.filter((column) => {
        const advancedSettings = column.state.settings?.state?.advanced;
        const visibilityConditions = advancedSettings?.visibilityCondition;

        return visibilityConditions ? visibilityConditions.check() : true;
      });

      if (visibleColumns.map((column) => column.state.originalSize).reduce((a, b) => a + b, 0) !== 12) {
        let size = 12;

        switch (visibleColumns.length) {
          case 2:
            size = 6;
            break;

          case 3:
            size = 4;
            break;

          case 4:
            size = 3;
            break;

          case 6:
            size = 2;
            break;
        }

        visibleColumns.forEach((column) => {
          column.state.size = size;
        });
      } else {
        visibleColumns.forEach((column) => {
          column.state.size = column.state.originalSize;
        });
      }

      return visibleColumns;
    });

    watch(
      () => props.model.state.edit?.isActive,
      (isActive) => {
        if (isActive && campaignStore.model?.state.isEditModeActive) {
          props.model.section.state.edit?.sectionRef?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    );

    // InterSectionObserver data
    let playedEnter = false;

    const onAnimationEnd = (event: AnimationEvent) => {
      if (
        event.target instanceof HTMLElement &&
        props.model.state.classIdentifier &&
        event.target.classList.contains(props.model.state.classIdentifier)
      ) {
        rowRef.value?.removeEventListener('animationend', onAnimationEnd);
        hasAnimatedIn.value = true;
      }
    };

    onMounted(() => {
      rowRef.value?.addEventListener('animationend', onAnimationEnd);
      if (rowRef.value && rowRef.value instanceof Element) {
        // model.setSectionRefElement(sectionRef.value);

        // only section should have InterSectionObserver
        if (shouldAnimateIn.value) {
          if ('IntersectionObserver' in window) {
            // observe if section is in intersection
            const observer = new IntersectionObserver(([entry]) => {
              if (entry && entry.isIntersecting && !playedEnter && rowRef.value && rowRef.value instanceof Element) {
                observer.unobserve(rowRef.value);
                playedEnter = true;

                // Play animation
                animateIn.value = true;
                shouldAnimateIn.value = false;
              }
            });

            observer.observe(rowRef.value);
          } else {
            // Play animation
            animateIn.value = true;
            shouldAnimateIn.value = false;
          }
        }
      }
    });

    return {
      isCssEnabled,
      rowStyles,
      rowBackgroundStyle,
      rowBackgroundImageStyle,
      rowBackgroundOverlayStyle,
      rowClasses,
      rowAlign,
      showContextMenu,
      setFocus,
      removeFocus,
      toggleActive,
      columns,
      removeActive,
      campaignStore,
      editingStore,
      rowRef,
      shouldShowRepeatable,
      repeatableColumns,
      OverlayType,
      BackgroundType,
      rowCssStyle
    };
  }
});
</script>

<style lang="scss">
.site--editmode-on {
  .row {
    .edit-title {
      display: none;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid transparent;
      z-index: 10;
      pointer-events: none;
    }
    &--edit-focus {
      &:before {
        border-style: dashed;
        border-color: $edit-border-hover-color;
      }

      & > .edit-title {
        display: block;
        @include lf-title();
        background-color: rgba(#878787, 0.9);
        color: #fff;
      }
    }
    &--active-in-navigator {
      &:before {
        border-color: $edit-border-color;
      }

      & > .edit-title {
        @include lf-title();
        background-color: $edit-border-color;
        color: #fff;
      }
    }
  }
}
</style>
