<template>
  <component is="style" v-if="model.state.elementStyling?.cssStyle" type="text/css">
    {{ model.state.elementStyling.cssStyle }}
  </component>

  <div
    class="registration-form-wrapper"
    :style="model.state?.elementStyling?.container"
    :class="{ 'not-valid-settings': !model.isAddonValid() }"
  >
    <Flow>
      <FlowPage
        v-if="!showConfirmation && !messageState"
        key="form"
        :animation-enter="defaultEnterAnimation"
        :animation-leave="leaveAnimation"
      >
        <div
          class="registration-form"
          :class="{
            'registration-form--inline-messages': model.state.appearance?.formStyle?.validationMessageType === 'inline'
          }"
          :style="model.state.elementStyling?.inline"
        >
          <div class="row">
            <div class="col">
              <div class="container">
                <form
                  :id="formName"
                  ref="formRef"
                  :data-form-id="formName"
                  class="content__item content__item--form"
                  :autocomplete="model.state.disableFormAutoComplete ? 'off' : ''"
                  :class="{
                    'content__item--form-autofilled': hasAutofilled,
                    invalid: !isValid,
                    valid: isValid,
                    error: isError
                  }"
                  @submit.prevent="submitForm"
                >
                  <div class="row">
                    <FormElement
                      v-for="formField in fields"
                      :key="formField.id"
                      :model="formField"
                      :show-validation-errors="didSubmit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-if="showButton" class="row">
            <div class="col">
              <div
                class="content__item content__item--action container"
                :style="model.state.elementStyling?.buttonContainerStyle"
              >
                <div class="row">
                  <div class="col">
                    <UIButton :is-loading="isLoading" :button-style="model.state.button?.type" @click="submitForm">
                      <!-- nosem -->
                      <span class="content__item-action-text" v-html="model.state.button?.label || 'Send'"></span>
                    </UIButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FlowPage>

      <FlowPage v-else-if="messageState" key="message">
        <div class="content__item content__item--text">
          <div class="row row--message" :style="messageState.inlineStyles">
            <h2 :style="messageState.inlineStyles">{{ messageState.headline ?? '' }}</h2>
            <div class="message-body" :style="messageState.inlineStyles">{{ messageState.description ?? '' }}</div>
          </div>
        </div>
      </FlowPage>

      <FlowPage v-else-if="showConfirmation" key="confirmation">
        <RichTextRenderer
          class="registration-confirmation"
          tag="div"
          :html="model.state?.confirmation?.message ?? ''"
        ></RichTextRenderer>
      </FlowPage>
    </Flow>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, getCurrentInstance, nextTick, onBeforeUnmount, ref } from 'vue';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import FormElement from '@/src/components/content/form/elements/FormElement.vue';
import useDevice from '@/src/hooks/useDevice';
import {
  saveGlobalFormCookies,
  setFormReplacementTags,
  submitForm as submitRegistrationForm
} from '@/src/utilities/Registration';
import { CampaignDeviceType, SectionType } from '@/src/typings/enums/enums';
import Flow from '@/src/components/Flow/Flow.vue';
import FlowPage from '@/src/components/Flow/FlowPage.vue';
import { defaultEnterAnimation, defaultLeaveAnimation } from '@/src/components/Flow/Types';
import useFlow from '@/src/hooks/useFlow';
import type { FormElementModel } from '@/src/components/addons/registration/types';
import { recursivelyWaitForPromises } from '@/src/utilities/virtualDom';
import { events } from '@/src/services/events';
import type { FormSubmitEvent } from '@/src/events/formSubmit';
import type { FormSubmitResponseEvent } from '@/src/events/formSubmitResponse';
import { redirect, RedirectTarget } from '@/src/services/url';
import UIButton from '@/src/components/ui/UIButton.vue';
import { FormDisableDefaultBehavior } from '@/src/exceptions/FormDisableDefaultBehavior';
import type { MessagesState } from '@/src/models/messages/MessagesHandlerModel';
import MessagesHandlerModel from '@/src/models/messages/MessagesHandlerModel';
import { MessageDisplayTypes } from '@/src/typings/interfaces/data/campaign';
import { addDataLayerEvent } from '@/src/utilities/Tracking';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { FlowpageModel } from '@/src/components/layout/FlowpageModel';
import RichTextRenderer from '@/src/components/RichTextRenderer.vue';
import { RedirectOptions } from '@/src/components/addons/registration/Data';

export default defineComponent({
  name: 'AddonRegistration',
  components: {
    RichTextRenderer,
    UIButton,
    FlowPage,
    Flow,
    FormElement
  },
  props: {
    model: {
      type: Object as PropType<AddonRegistrationModel>,
      required: true
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();

    props.model.state.fields?.forEach((field) => {
      field.setInitialValue();
    });

    const showButton = ref(true);
    const showConfirmation = ref(false);
    const hasAutofilled = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);
    const didSubmit = ref(false);
    const formRef = ref<HTMLElement | null>(null);
    let disallowFlowpageChange = false;
    let sentGaEvent = false;

    const isValid = computed(() => {
      return fields.value.filter((field) => !field.state.isValid).length === 0;
    });

    const formName = computed(() => {
      return props.model.state.formId;
    });

    const allowedToAutoSubmit = computed(() => {
      const campaignModel = campaignStore.model;
      const section = props.model.getSection();

      return (
        props.model.state.skipRegistration &&
        // Disable auto-submit in editing mode
        !campaignModel?.state.isEditModeActive &&
        // Only allow autosubmit for flowpages & sections
        (section.getSectionType() === SectionType.FLOWPAGE || section.getSectionType() === SectionType.SECTION) &&
        // Require all fields to be valid before auto-submit is possible
        isValid.value
      );
    });

    // Validation happens async. We need to ensure that we only allow people to submit when all has been validated.
    const isValidated = computed(() => {
      return fields.value.filter((field) => !field.state.validated).length === 0;
    });

    if (props.model.getSection().getSectionType() === SectionType.FLOWPAGE) {
      showButton.value = false;
    }

    const submitForm = async () => {
      if (!didSubmit.value) {
        didSubmit.value = true;
      }

      if (isValid.value && isValidated.value) {
        isLoading.value = true;

        try {
          await submitRegistrationForm(props.model.getSection());
          setFormReplacementTags();
        } catch (e) {
          if (e instanceof FormDisableDefaultBehavior) {
            disallowFlowpageChange = !e.allowContinueInFlow;
          } else {
            // If exception wasn't the exception to disable default behavior we should bubble up the exception.
            throw e;
          }
        }
      } else {
        events.emit('formInvalidFields', props.model.getSection());
        await scrollToFormErrors();
      }
    };

    const scrollToFormErrors = async () => {
      if (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS) {
        return;
      }

      await nextTick();
      const form = formRef.value;

      if (form) {
        const error = form.querySelector<HTMLElement>('.form-group--error');

        // If no error element is found, then don't scroll
        if (!error) {
          return;
        }

        // Only scroll to the error if it is not within the viewport
        error.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    };

    const fields = computed<FormElementModel[]>(() => {
      const { isDesktop, isTablet, isMobile } = useDevice();

      return (
        props.model.state?.fields?.filter((field) => {
          const fieldState = field.state;
          const conditions = fieldState?.visibilityConditions;

          if (fieldState.deviceSettings && !fieldState.deviceSettings.showOnDesktop && isDesktop) {
            return false;
          }

          if (fieldState.deviceSettings && !fieldState.deviceSettings.showOnTablet && isTablet) {
            return false;
          }

          if (fieldState.deviceSettings && !fieldState.deviceSettings.showOnMobile && isMobile) {
            return false;
          }

          return conditions ? conditions.check() : true;
        }) ?? []
      );
    });

    const currentInstance = getCurrentInstance();
    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    nextTick(async () => {
      if (currentInstance) {
        await recursivelyWaitForPromises(currentInstance.vnode, 'onBeforeEnter', 2);
      }

      await nextTick();

      let goToNextFlowPage = false;
      const section = props.model.getSection();

      if (allowedToAutoSubmit.value) {
        goToNextFlowPage = true;
        section.hide();

        await submitForm();
        await nextTick();
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }

      if (goToNextFlowPage && !disallowFlowpageChange && allowedToAutoSubmit.value) {
        await nextTick();
        useFlow().goToNextFlowPage();
      }
    });

    const onFormSubmit = (event: FormSubmitEvent) => {
      if (event.detail === props.model.getSection()) {
        if (!didSubmit.value) {
          didSubmit.value = true;
        }

        if (!isValid.value || !isValidated.value) {
          event.preventDefault();
          scrollToFormErrors();
          return;
        }

        event.suspense(async () => {
          const validationResult = await Promise.all(
            props.model.state.fields?.map((field) => {
              return field.validateOnSubmit();
            }) ?? []
          );

          if (validationResult.includes(false)) {
            throw new FormDisableDefaultBehavior();
          }

          const replacementTags: ReplacementTags = {};

          props.model.state.fields?.forEach((field) => {
            if (field.shouldSetReplacementTags) {
              replacementTags[`registration_field_${field.id}`] = field.getSerializedCookieValue();
            }
          });

          campaignStore.addReplacementTags(replacementTags);

          setFormReplacementTags();

          // Initiate save of global form cookies before saving the actual form.
          saveGlobalFormCookies(props.model.state.fields ?? []);
        });
      }
    };

    const sendGaEvent = () => {
      if (!props.model.state.ga?.action || sentGaEvent) {
        return;
      }

      sentGaEvent = true;

      if (typeof gtag === 'function') {
        gtag('event', props.model.state.ga.action, {
          event_category: props.model.state.ga.category,
          event_label: props.model.state.ga.label,
          value: props.model.state.ga.value ? parseInt(props.model.state.ga.value) : undefined,
          debug_mode: true
        });
      } else if (typeof ga !== 'undefined') {
        ga('send', {
          hitType: 'event',
          eventCategory: props.model.state.ga.category,
          eventAction: props.model.state.ga.action,
          eventLabel: props.model.state.ga.label,
          eventValue: props.model.state.ga.value ? parseInt(props.model.state.ga.value) : undefined
        });
      }
    };

    const messageState = ref<MessagesState | undefined>();

    const onFormSubmitResponse = async (event: FormSubmitResponseEvent) => {
      const campaignModel = campaignStore.model;
      const campaignState = campaignModel?.state;
      if (event.detail.pageModel !== props.model.getSection()) {
        return;
      }

      const response = event.detail.response;

      if (!response.error) {
        sendGaEvent();
      }

      /**
       * Handle when we a game limit has been reached (eg: Number of registrations pr day)
       */
      if (response.error && response.message === 'game limit reached') {
        addDataLayerEvent('registration_limit', {
          pageId: props.model.getSection().id,
          pageTitle: props.model.getSection().state.title ?? 'Page'
        });

        events.emit('formRegistrationLimit', props.model.getSection());

        if (
          !(props.model.getSection() instanceof FlowpageModel) &&
          campaignState?.messages.messageGameLimit.state.displayType === MessageDisplayTypes.INLINE_MESSAGE
        ) {
          messageState.value = campaignState?.messages.messageGameLimit.state;
        } else {
          campaignState?.messages.messageGameLimit.trigger();
        }

        event.preventDefault();
        return;
      }

      /**
       * Handle when we a overall limit has been reached (eg: Total number of registrations pr day)
       */
      if (response.error && response.message === 'overall limit reached') {
        addDataLayerEvent('overall_registration_limit', {
          pageId: props.model.getSection().id,
          pageTitle: props.model.getSection().state.title ?? 'Page'
        });

        if (
          !(props.model.getSection() instanceof FlowpageModel) &&
          campaignState?.messages.messageOverallLimit.state.displayType === MessageDisplayTypes.INLINE_MESSAGE
        ) {
          messageState.value = campaignState?.messages.messageOverallLimit.state;
        } else {
          campaignState?.messages.messageOverallLimit.trigger();
        }

        event.preventDefault();
        return;
      }

      /**
       * Handle when we identify a user that is trying to cheat
       */
      if (response.object && response.object.token === '61c15628d7585e4aeb78e3ac0e8a7d35') {
        campaignState?.messages.messageCheating.trigger();
        event.preventDefault();
        return;
      }

      events.emit('formSubmitted', props.model.getSection());

      if (response.error && response.show_message) {
        const messageModel = new MessagesHandlerModel(response.show_message);

        const hasGameFlowAddon = campaignModel?.state.sections.some(
          (section) => section.getAddons('gameflow').length > 0
        );

        if (!hasGameFlowAddon && messageModel.state.displayType === MessageDisplayTypes.INLINE_MESSAGE) {
          messageState.value = messageModel.state;
          const section = props.model.getSection();
          section.show();
        } else {
          messageModel.trigger();
        }
        event.preventDefault();
        return;
      }

      if (
        props.model.state.redirect &&
        props.model.state.redirect.url &&
        props.model.state.redirect.options === RedirectOptions.REDIRECT
      ) {
        redirect({
          url: props.model.state.redirect.url,
          target: RedirectTarget.SELF
        });
        return;
      }

      // Response can include a flow page that the handler should go-to instead of the default behavior.
      // So here we throw a prevent behavior exception for the default behavior.
      // This exception is then caught in the button addon which initiated the form submit.
      if (response.goto_flow_page) {
        const { makeSpecificFlowPageActive } = useFlow();
        makeSpecificFlowPageActive(response.goto_flow_page);
        event.preventDefault();
        return;
      }

      if (response.isError) {
        isError.value = true;
      } else {
        isLoading.value = false;

        if (props.model.getSection().getSectionType() !== SectionType.FLOWPAGE) {
          showConfirmation.value = true;
        }
      }
    };

    events.on('formSubmit', onFormSubmit);
    events.on('formSubmitResponse', onFormSubmitResponse);

    onBeforeUnmount(() => {
      events.off('formSubmit', onFormSubmit);
      events.off('formSubmitResponse', onFormSubmitResponse);
    });

    return {
      formRef,
      formName,
      isValid,
      hasAutofilled,
      showButton,
      didSubmit,
      submitForm,
      isLoading,
      isError,
      messageState,
      showConfirmation,
      fields,
      defaultEnterAnimation,
      leaveAnimation: defaultLeaveAnimation,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss" scoped>
.site--editmode-on .registration-form-wrapper {
  &.not-valid-settings {
    border: 0.2rem dashed hsla(0, 0%, 100%, 0.3);
    height: 100%;
  }
}

.site--ads.site--ads-type-fixed {
  .container {
    max-width: 100%;
  }
}
</style>

<style lang="scss">
.registration-form,
.registration-confirmation {
  .content__item--action {
    margin-bottom: 0;
  }
}
.grid__addon.grid__addon.grid__addon--registration,
.grid__addon.grid__addon--width-auto.grid__addon--registration,
.grid__addon.grid__addon--width-custom.grid__addon--registration {
  width: 100%;
}
</style>
