import type { InputStyleType, ValidationMessageType } from './enums';
import type { AlignContentType, ButtonStyleType } from '@/src/typings/enums/enums';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';
import type { FormElementData } from '@/src/components/addons/registration/types';

export enum RedirectOptions {
  CONFIRM = 'confirm',
  REDIRECT = 'redirect'
}

export interface AddonRegistrationAppearanceDevice {
  font_size?: string;
  background_color?: string;
  stroke_color?: string;
  text_color?: string;
  placeholder_color?: string;
  label_color?: string;
  check_color?: string;
  check_background?: string;
  validation_color?: string;
  element_space?: number;
}

export interface AddonRegistrationAppearanceData {
  input_style?: InputStyleType;
  font_size?: string;
  background_color?: string;
  stroke_color?: string;
  text_color?: string;
  placeholder_color?: string;
  label_color?: string;
  check_color?: string;
  check_background?: string;
  validation_color?: string;
  element_space?: number;
  overwrite_tablet?: string;
  overwrite_mobile?: string;
  form_style?: {
    validation_message_type?: ValidationMessageType;
    background_color?: string;
    stroke_color?: string;
  };
  mobile?: AddonRegistrationAppearanceDevice;
  tablet?: AddonRegistrationAppearanceDevice;
}

export interface AddonRegistrationData {
  alias: string;
  settings: {
    settings?: {
      disable_cookies?: string;
      skip_registration?: string;
      disable_autocomplete?: string;
    };
    ga?: {
      ga_event_enable: string;
      ga_event_category?: string;
      ga_event_action?: string;
      ga_event_label?: string;
      ga_event_value?: string;
    };
    form_layout?: {
      form_width?: string;
      form_align?: AlignContentType;
    };
    redirect_options?: RedirectOptions;
    redirect_url?: string;
    confirmation?: {
      message?: string;
    };
    button?: {
      enabled?: string;
      label: string;
      type: ButtonStyleType;
      alignment: AlignContentType;
      background_color?: string;
      text_color?: string;
    };
    appearance?: AddonRegistrationAppearanceData;
    advanced?: AdvancedAddonsStyleData;
    fields?: FormElementData[];
    endpoint?: string;
  };
}
