<template>
  <div
    :id="model.getSectionElementId"
    ref="sectionRef"
    v-click-away="removeActive"
    class="row section"
    :class="sectionClasses"
    :data-title="model.state?.title"
    :style="sectionStyles"
    @mouseover.stop="setFocus"
    @mouseout.stop="removeFocus"
    @click.right="showContextMenu($event, model, state.edit?.isActive ?? false)"
    @click="onClick($event)"
  >
    <component is="style" v-if="state.customCss || sectionCssStyle" type="text/css">
      {{ isCssEnabled ? model.state?.customCss?.state?.code ?? '' : '' }}
      {{ sectionCssStyle }}
    </component>
    <SectionIsCloneMessage
      v-if="model.state.config.clone && campaignStore.model?.state.isEditModeActive && model.state.edit"
      :clone-of-title="model.state.edit.cloneOfTitle"
      :clone-of-description="model.state.edit.cloneOfDescription"
      :clone-of-link="model.state.edit.cloneOfLink"
      :clone-id="model.state.config.cloneId"
    ></SectionIsCloneMessage>

    <SectionEditToolbar
      v-if="
        campaignStore.model?.state.isEditModeActive &&
        model.state.edit &&
        model.state.edit.isActive &&
        editingStore.activeTabCategory === SectionType.SECTION &&
        editingStore.activeModel === model
      "
      :model="model"
    />
    <div
      class="section__background-color"
      :style="{ ...(backgroundStyleWidth && { width: backgroundStyleWidth }), ...backgroundColorStyles }"
    ></div>
    <div
      v-if="model.state.config.settings.state.sectionBackground"
      class="section__background"
      :style="{ ...(backgroundStyleWidth && { width: backgroundStyleWidth }) }"
    >
      <BackgroundImage v-if="backgroundImageStyle.enabled" :style="backgroundImageStyle?.state" />
      <BackgroundVideoSlider
        v-if="sectionBackgroundVideoStyles.enabled"
        :video="sectionBackgroundVideoStyles?.state"
        :section-background-styles="sectionBackgroundVideoStyles?.style"
        :video-background-styles="sectionBackgroundVideoStyles?.style"
        :ratio="videoRatio"
      />

      <BackgroundImagesSlider
        v-if="
          sectionBackgroundImageSlidesStyles.enabled &&
          sectionBackgroundImageSlidesStyles.slides &&
          sectionBackgroundImageSlidesStyles.slides.length > 0
        "
        :items="sectionBackgroundImageSlidesStyles.slides"
        :transition="sectionBackgroundImageSlidesStyles?.state?.transition"
        :transition-duration="sectionBackgroundImageSlidesStyles?.state?.transitionTime"
        :transition-interval="sectionBackgroundImageSlidesStyles?.state?.interval?.time"
      />
    </div>

    <div
      v-if="sectionOverlayStyles && Object.keys(sectionOverlayStyles).length > 0"
      class="section__background-overlay sectionOverlayStyles"
      :style="{ ...(backgroundStyleWidth && { width: backgroundStyleWidth }), ...sectionOverlayStyles }"
    ></div>
    <div
      v-if="gameSectionColorOverlay && Object.keys(gameSectionColorOverlay).length > 0"
      class="section__background-overlay gameSectionColorOverlay"
      :style="{ ...(backgroundStyleWidth && { width: backgroundStyleWidth }), ...gameSectionColorOverlay }"
    ></div>

    <UIDivider
      v-if="model.state.config.settings.state.config.isDividerEnabled"
      :settings="model.state.config.settings.state.style?.divider"
      :section-id="model.getSection().id"
    />

    <div v-if="model.state?.content" class="col section__content">
      <div v-for="(content, index) in model.state.content" :key="index" class="content">
        <ContentGrid
          v-if="content.type === ContentType.GRID"
          :content="content"
          :section-id="Number(model.state?.id)"
          :grid-type="model.state.config.settings?.state?.basic?.height?.layout"
          :style="gridStyles"
        />
        <ContentText v-else-if="content.type === ContentType.TEXT" v-html="content.content" /><!-- nosem -->
        <ContentComponent v-else-if="content.type === ContentType.COMPONENT" :content="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { onUnmounted } from 'vue';
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, onUpdated, ref, watch } from 'vue';
import type { AddonGameflowModel } from '../addons/gameflow/Model';
import type { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import UIDivider from '@/src/components/ui/UIDivider.vue';
import {
  ActionType,
  ActiveElementTypes,
  BackgroundType,
  BehaviorTypes,
  ContentPositionType,
  GapType,
  HeightType,
  OverlayType,
  PositionTypes,
  SectionType,
  TransitionEnterType
} from '@/src/typings/enums/enums';
import BackgroundVideoSlider from '@/src/components/ui/BackgroundVideoSlider.vue';
import BackgroundImagesSlider from '@/src/components/ui/BackgroundImagesSlider.vue';
import useNavigator from '@/src/hooks/useNavigator';
import SectionEditToolbar from '@/src/components/components/editing/SectionEditToolbar.vue';
import type { FlowAnimation } from '@/src/components/Flow/Types';
import SectionIsCloneMessage from '@/src/components/components/editing/SectionIsCloneMessage.vue';
import { events } from '@/src/services/events';
import { recursivelyWaitForPromises } from '@/src/utilities/virtualDom';
import { applyReplacementTags, debounce, formatColor, inIframe, preloadImagePromise } from '@/src/utilities/Utilities';
import useAnalytics from '@/src/hooks/useAnalytics';
import useLeadScore from '@/src/hooks/useLeadscore';
import BackgroundImage from '@/src/components/ui/BackgroundImage.vue';
import ContentGrid from '@/src/components/content/ContentGrid.vue';
import ContentComponent from '@/src/components/content/ContentComponent.vue';
import ContentText from '@/src/components/content/ContentText.vue';
import useLayoutSettings from '@/src/components/layout/hooks/useInlineLayoutStyles';
import useFlow from '@/src/hooks/useFlow';
import { PopoverModel } from '@/src/models/PopoverModel';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';
import { useUtilityStore } from '@/src/store/utility';
import type { AddonGameplayModel } from '@/src/components/addons/gameplay/Model';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'LFSection',
  components: {
    SectionIsCloneMessage,
    SectionEditToolbar,
    BackgroundImagesSlider,
    BackgroundVideoSlider,
    BackgroundImage,
    UIDivider,
    ContentGrid,
    ContentComponent,
    ContentText
  },
  props: {
    model: {
      type: Object as PropType<SectionBaseModel>,
      required: true
    },
    sectionOrder: Number
  },
  setup: (props) => {
    const route = useRoute();
    const campaignStore = useCampaignStore();
    const sectionRef = ref<Element>();
    const videoWidth = ref(100);
    const videoRatio = ref(true);
    const animateIn = ref(false);
    const hasAnimatedIn = ref(false);
    const failedTrackedAttempts = ref(0);

    const editingStore = useEditingStore();
    const utilityStore = useUtilityStore();

    const isAnimatingIn = ref(false);
    const { setFocus, removeFocus } = useNavigator(props.model);

    const isReady = ref(false);

    const sectionType = computed(() => props.model.getSectionType());
    const isSection = computed(() => sectionType.value === SectionType.SECTION);
    const isFlowPage = computed(() => sectionType.value === SectionType.FLOWPAGE);
    const isPopover = computed(() => sectionType.value === SectionType.POPOVER);

    const shouldAnimateIn = ref(
      props.model.state.config.settings.state.advanced?.transitions?.enter &&
        sectionType.value === SectionType.SECTION &&
        props.model.state.config.settings.state.advanced.transitions.enter !== 'none'
    );

    const isCssEnabled = computed(() => {
      return campaignStore.enableCSS;
    });

    const backgroundStyleWidth = computed(() => {
      if (
        props.model.state.config.settings.state?.basic?.height &&
        props.model.state.config.settings.state?.basic?.height?.layout === 'grid'
      ) {
        if (backgroundHasBorder.value) {
          return '100%';
        }
        return props.model.state.config.settings.state?.basic?.height?.width;
      }
      return null;
    });

    const backgroundHasBorder = computed(() => {
      let borderWidth = 0;
      const propsBorder = props.model?.state?.config?.settings?.state.style.border?.width;

      if (propsBorder) {
        switch (true) {
          case typeof propsBorder.bottom === 'number':
            borderWidth += propsBorder.bottom ?? 0;
            break;

          case typeof propsBorder.top === 'number':
            borderWidth += propsBorder.top ?? 0;
            break;

          case typeof propsBorder.left === 'number':
            borderWidth += propsBorder.left ?? 0;
            break;

          case typeof propsBorder.right === 'number':
            borderWidth += propsBorder.right ?? 0;
            break;
        }
      }

      return borderWidth > 0;
    });

    const gameSectionColorOverlay = computed<CSSProperties | undefined>(() => {
      const overlay = campaignStore.gameSectionImageOverlay;

      if (props.model.getAddons<AddonGameflowModel>('gameflow').length === 0) {
        return undefined;
      }
      if (overlay?.overlay) {
        return {
          backgroundColor: formatColor(overlay.overlay)
        };
      }
      return undefined;
    });

    const sectionBackgroundImageSlidesStyles = computed(() => {
      const currentFlowPage = campaignStore.flowModel;
      const flowPageState = currentFlowPage?.state?.config?.settings?.state;

      if (
        !props.model.state.config.hasGameflowAddon &&
        props.model.state.config.settings.state.config.isSliderEnabled &&
        !flowPageState?.style.background?.backgroundToSection
      ) {
        return {
          state: { ...props.model.state.config.settings.state.style?.background?.slider },
          slides: props.model.state.config.settings.state.backgroundSlides,
          enabled: props.model.state.config.settings.state.config.isSliderEnabled
        };
      }

      if (props.model.state.config.hasGameflowAddon) {
        if (
          props.model.state.id !== currentFlowPage?.state.id &&
          flowPageState?.config.isSliderEnabled &&
          flowPageState?.style.background?.backgroundToSection
        ) {
          return {
            state: { ...flowPageState?.style?.background?.slider },
            slides: flowPageState?.backgroundSlides,
            enabled: flowPageState?.config.isSliderEnabled
          };
        } else {
          return {
            state: { ...props.model.state.config.settings.state.style?.background?.slider },
            slides: props.model.state.config.settings.state.backgroundSlides,
            enabled: props.model.state.config.settings.state.config.isSliderEnabled
          };
        }
      }
      return {
        enabled: false
      };
    });

    const sectionBackgroundVideoStyles = computed(() => {
      const currentFlowPage = campaignStore.flowModel;
      const flowPageState = currentFlowPage?.state?.config?.settings?.state;

      if (
        !props.model.state.config.hasGameflowAddon &&
        !props.model.state.config.settings.state.config.isBackgroundImageEnabled &&
        props.model.state.config.settings.state.config.isVideoEnabled &&
        !flowPageState?.style.background?.backgroundToSection
      ) {
        return {
          enabled: props.model.state.config.settings.state.config.isVideoEnabled,
          style: { ...props.model.state.config.settings.state.elementStyling.background?.video },
          state: { ...props.model.state.config.settings.state.style?.background?.video }
        };
      }

      if (props.model.state.config.hasGameflowAddon) {
        if (
          props.model.state.id !== currentFlowPage?.state.id &&
          flowPageState?.config.isVideoEnabled &&
          flowPageState?.style.background?.backgroundToSection &&
          flowPageState?.style.background?.backgroundType !== BackgroundType.TRANSPARENT
        ) {
          return {
            enabled: flowPageState?.config.isVideoEnabled,
            style: { ...flowPageState?.elementStyling?.background?.video },
            state: { ...flowPageState?.style?.background?.video }
          };
        } else if (flowPageState) {
          return {
            enabled:
              props.model.state.config.settings.state.config.isVideoEnabled &&
              !flowPageState?.style.background?.backgroundToSection,
            style: { ...props.model.state.config.settings.state.elementStyling.background?.video },
            state: { ...props.model.state.config.settings.state.style?.background?.video }
          };
        } else {
          return {
            enabled: props.model.state.config.settings.state.config.isVideoEnabled,
            style: { ...props.model.state.config.settings.state.elementStyling.background?.video },
            state: { ...props.model.state.config.settings.state.style?.background?.video }
          };
        }
      }
      return {
        enabled: false
      };
    });

    const sectionOverlayStyles = computed(() => {
      const currentFlowPage = campaignStore.flowModel;
      const flowPageState = currentFlowPage?.state?.config?.settings?.state;

      let propsOverlayEnabled = false;
      let flowOverlayEnabled = false;

      if (
        props.model.state.config.settings.state.config.isOverlayEnabled ||
        props.model.state.config.settings.state.config.isOverlayGradientEnabled
      ) {
        propsOverlayEnabled = true;
      }

      if (flowPageState?.config?.isOverlayEnabled || flowPageState?.config?.isOverlayGradientEnabled) {
        flowOverlayEnabled = true;
      }

      // Not inside flowpages
      if (!props.model.state.config.hasGameflowAddon) {
        // adds props overlay if flow overlay dont have "overlay to section"
        // if flow has overlay to section then it should overwrite current prop overlay style
        if (propsOverlayEnabled && !flowPageState?.style.overlay?.overlayToSection) {
          return {
            ...props.model.state.config.settings.state.elementStyling?.background?.overlay,
            ...(props.model.state.config.settings.state.basic?.height &&
              props.model.state.config.settings.state.basic?.height?.layout === 'grid' &&
              backgroundStyleWidth.value && {
                width: backgroundStyleWidth.value
              })
          };
        }
        // adds overlay if current props isn't equal to the current flowpage
        if (propsOverlayEnabled && props.model.state.id !== currentFlowPage?.state.id) {
          return {
            ...props.model.state.config.settings.state.elementStyling?.background?.overlay
          };
        }
      }

      // inside flowpages
      if (props.model.state.config.hasGameflowAddon) {
        // adds props overlay if flow overlay dont have "overlay to section"
        if (propsOverlayEnabled && !flowPageState?.style.overlay?.overlayToSection) {
          return {
            background: props.model.state.config.settings.state.elementStyling?.background?.overlay?.background
          };
        }

        // checks for props is matching flow and dont have overlay to section
        if (
          props.model.state.id === currentFlowPage?.state.id &&
          !flowPageState?.style.overlay?.overlayToSection &&
          flowOverlayEnabled &&
          flowPageState?.style?.overlay?.overlayType === OverlayType.TRANSPARENT
        ) {
          return {
            background: props.model.state.config.settings.state.elementStyling?.background?.overlay?.background
          };
        }

        // checks for unique flow overlay that has overlay to section
        // if unique and dont match the props, then we return the flow instead
        if (
          props.model.state.id !== currentFlowPage?.state.id &&
          flowPageState?.style.overlay?.overlayToSection &&
          flowOverlayEnabled &&
          flowPageState?.style.overlay.overlayType !== OverlayType.TRANSPARENT
        ) {
          return {
            ...flowPageState?.elementStyling?.background?.overlay
          };
        }
      }

      // if all other if statements goes through -> it should default always add current overlay if enabled
      if (propsOverlayEnabled && !props.model.state.config.settings.state.style.overlay?.overlayToSection) {
        return {
          ...props.model.state.config.settings.state.elementStyling?.background?.overlay
        };
      }

      return {};
    });

    const backgroundImageStyle = ref<{ enabled: boolean; state?: CSSProperties }>({ enabled: false });

    const backgroundImageStyleComputed = computed<{ enabled: boolean; state?: CSSProperties }>(() => {
      // START game background overlay
      // if we have a game overlay background - then we should use that as the section background
      const overlay = campaignStore.gameSectionImageOverlay;
      // make sure we don't add it inside our gameflow
      if (overlay?.src && props.model.getAddons<AddonGameflowModel>('gameflow').length !== 0) {
        let position: string | undefined;
        let behaviour: string | undefined;

        if (overlay?.position !== undefined) {
          switch (overlay.position) {
            case PositionTypes.CUSTOM:
              position = 'custom';
              break;
            case PositionTypes.BOTTOM_CENTER:
              position = 'bottom center';
              break;
            case PositionTypes.BOTTOM_LEFT:
              position = 'bottom left';
              break;
            case PositionTypes.BOTTOM_RIGHT:
              position = 'bottom right';
              break;
            case PositionTypes.CENTER_CENTER:
              position = 'center center';
              break;
            case PositionTypes.CENTER_LEFT:
              position = 'center left';
              break;
            case PositionTypes.CENTER_RIGHT:
              position = 'center right';
              break;
            case PositionTypes.TOP_CENTER:
              position = 'top center';
              break;
            case PositionTypes.TOP_LEFT:
              position = 'top left';
              break;
            case PositionTypes.TOP_RIGHT:
              position = 'top right';
              break;
          }
        }

        if (overlay?.behavior !== undefined) {
          switch (overlay.behavior) {
            case BehaviorTypes.CENTER:
              break;
            case BehaviorTypes.ACTUAL_SIZE:
              behaviour = 'initial';
              break;
            case BehaviorTypes.COVER:
              behaviour = 'cover';
              break;
            case BehaviorTypes.CONTAIN:
              behaviour = 'contain';
              break;
            case BehaviorTypes.STRETCH:
              behaviour = '100% 100%';
              break;
            case BehaviorTypes.TILE:
              behaviour = 'tile';
              break;
          }
        }

        return {
          state: {
            backgroundImage: `url(${overlay?.src})`,
            ...(position && { backgroundPosition: position }),
            backgroundRepeat: behaviour === 'tile' ? overlay.tileRepeat : 'no-repeat',
            ...(behaviour && { backgroundSize: behaviour })
          },
          enabled: true
        };
      }

      // END game background overlay

      // START Background style
      const currentFlowPage = campaignStore.flowModel;
      const flowPageState = currentFlowPage?.state?.config?.settings?.state;

      let propsBackgroundImage =
        props.model.state.config.settings.state.elementStyling.background?.image?.backgroundImage;
      let flowBackgroundImage = flowPageState?.elementStyling?.background?.image?.backgroundImage;

      if (propsBackgroundImage) {
        propsBackgroundImage = applyReplacementTags(propsBackgroundImage);
      }
      if (flowBackgroundImage) {
        flowBackgroundImage = applyReplacementTags(flowBackgroundImage);
      }

      if (!props.model.state.config.hasGameflowAddon) {
        if (
          props.model.state.config.settings.state.config.isBackgroundImageEnabled &&
          props.model.state.id !== currentFlowPage?.state?.id
        ) {
          return {
            state: {
              ...props.model.state.config.settings.state.elementStyling.background?.image,
              ...(propsBackgroundImage && {
                backgroundImage: propsBackgroundImage
              })
            },
            enabled: true
          };
        }

        if (props.model.state.config.settings.state.config.isBackgroundImageEnabled) {
          if (!flowPageState?.style.background?.backgroundToSection) {
            return {
              state: {
                ...props.model.state.config.settings.state.elementStyling.background?.image,
                ...(propsBackgroundImage && {
                  backgroundImage: propsBackgroundImage
                })
              },
              enabled: true
            };
          }
        }
      }

      if (props.model.state.config.hasGameflowAddon) {
        if (
          currentFlowPage &&
          props.model.state.id !== currentFlowPage.state.id &&
          flowPageState?.style.background?.backgroundToSection &&
          flowPageState.config.isBackgroundImageEnabled
        ) {
          return {
            state: { ...flowPageState?.elementStyling?.background?.image, backgroundImage: flowBackgroundImage },
            enabled: true
          };
        }
        if (
          props.model.state.config.settings.state.config.isBackgroundImageEnabled &&
          props.model.state.id !== currentFlowPage?.state?.id &&
          !flowPageState?.style.background?.backgroundToSection
        ) {
          return {
            state: {
              ...props.model.state.config.settings.state.elementStyling.background?.image,
              ...(propsBackgroundImage && {
                backgroundImage: propsBackgroundImage
              })
            },
            enabled: true
          };
        }

        if (
          props.model.state.config.settings.state.config.isBackgroundImageEnabled &&
          !flowPageState?.elementStyling?.background?.color?.backgroundColor &&
          !flowPageState?.config.isBackgroundImageEnabled
        ) {
          return {
            state: {
              ...props.model.state.config.settings.state.elementStyling.background?.image,
              ...(propsBackgroundImage && {
                backgroundImage: propsBackgroundImage
              })
            },
            enabled: true
          };
        }
      }

      return {
        enabled: false
      };
    });

    const backgroundImageStyleProcess = async () => {
      if (backgroundImageStyleComputed.value.enabled && backgroundImageStyleComputed.value.state?.backgroundImage) {
        const imageSrc = backgroundImageStyleComputed.value.state.backgroundImage
          .replace(/^url\(["']?/, '')
          .replace(/["']?\)$/, '');
        try {
          await preloadImagePromise(imageSrc);
          backgroundImageStyle.value.enabled = true;
          backgroundImageStyle.value.state = backgroundImageStyleComputed.value.state;
        } catch (e) {}

        return;
      }

      backgroundImageStyle.value.enabled = false;
      backgroundImageStyle.value.state = undefined;
    };

    // we need to watch our computed background style for background image changes, so we can react and preload the image before section
    watch(backgroundImageStyleComputed, debounce(backgroundImageStyleProcess, 300));

    const backgroundPreloadPromise = backgroundImageStyleProcess();

    const isFirstFlowPage = () => {
      return useFlow().getFirstFlowPage() === props.model.state.id;
    };

    props.model.setHiddenState(false);

    const gridStyles = computed<CSSProperties>(() => {
      if (isPopup) {
        return {};
      }

      if (
        props.model.state.config?.settings?.state?.basic?.height?.layout === 'grid' &&
        props.model.state.config.settings.state.basic?.height?.width
      ) {
        return {
          width: props.model.state.config.settings.state.basic?.height?.width,
          ...(props.model.state.config.settings.state.basic?.height?.width && { maxWidth: '100%' })
        };
      }
      return {};
    });

    const toggleActive = () => {
      // check if we are in edit mode and if we are in the same category
      // and fictive flow page is not the same as current model
      if (
        campaignStore.model?.state.isEditModeActive &&
        sectionType.value === editingStore.activeTabCategory &&
        campaignStore.fictiveFlowPage?.modelId !== props.model.modelId
      ) {
        editingStore.activeModel = props.model;
      }
    };

    const showContextMenu = (event: MouseEvent, model: SectionBaseModel, isActive: boolean) => {
      if (campaignStore.model?.state.isEditModeActive) {
        event.preventDefault();
        event.stopPropagation();

        editingStore.showContextMenu(isActive, event, model);
      }
    };

    const removeActive = () => {
      if (campaignStore.model?.state.isEditModeActive && sectionType.value === editingStore.activeTabCategory) {
        editingStore.activeModel = undefined;
      }
    };

    const isDemo = route.fullPath.includes('/campaign/view/demo');

    /**
     * Set video width
     */
    const setVideoWidth = () => {
      if (!props.model.state.config.settings.state.config.isVideoEnabled || !sectionRef.value) {
        return;
      }

      if (sectionRef.value.clientWidth / sectionRef.value.clientHeight < 1.77) {
        videoWidth.value = 1.77 * sectionRef.value.clientHeight;
        videoRatio.value = false;
      }
    };

    /**
     * Classes and styles from computed functions (Computed are cached)
     */
    const sectionClasses = computed(() => {
      let isSectionBackgroundCenter = false;
      let isSectionFixedHeight = false;

      if (
        props.model.state.config.settings.state.basic?.height &&
        props.model.state.config.settings.state.basic.height.layout === ContentType.GRID &&
        props.model.state.config.settings.state.basic.height.width
      ) {
        isSectionBackgroundCenter = true;
      }
      if (props.model.state.config.settings.state.basic?.height?.type === HeightType.FIXED) {
        isSectionFixedHeight = true;
      }

      const sectionOrder = props.sectionOrder;

      let sectionTypeClass;

      if (sectionType.value === SectionType.SECTION) {
        sectionTypeClass = `section--category-sections`;
      }
      if (sectionType.value === SectionType.FLOWPAGE) {
        sectionTypeClass = `section--category-flowpages`;
      }
      if (sectionType.value === SectionType.POPOVER) {
        sectionTypeClass = `section--category-popovers`;
      }

      const animateClassList: Record<string, boolean> = {};

      if (animateIn.value && !hasAnimatedIn.value) {
        animateClassList.animated = true;
        animateClassList[`${props.model.state.config.settings.state.advanced?.transitions?.enter}`] = true;
      }

      return {
        [`section--${props.model.getSectionElementId}`]: !!props.model.getSectionElementId,
        [`section--original-${props.model.state.id}`]: props.model.state.id,
        [`${props.model.state.classIdentifier}`]: true,
        'row--has-video': props.model.state.config.settings.state.config.isVideoEnabled === true,
        'no-gutters': props.model.state.config.settings.state.basic?.height?.gap === GapType.NO_GUTTER,
        'section--background-center': isSectionBackgroundCenter,
        'section--fixed-height': isSectionFixedHeight,
        'section--align-center':
          props.model.state.config.settings.state.basic?.height?.contentPosition === ContentPositionType.MIDDLE,
        'section--align-end':
          props.model.state.config.settings.state.basic?.height?.contentPosition === ContentPositionType.BOTTOM,
        [`${sectionTypeClass}`]: true,
        'section--addon-gameflow': props.model.state.config.hasGameflowAddon,
        flow__page: isFlowPage.value,
        'flow__page--ready': isFlowPage.value && isReady.value,
        [`flow__page--index-${sectionOrder}`]: isFlowPage.value,
        [`flow__page--type-${sectionType.value}`]: isFlowPage.value,
        'section--edit-focus': props.model.state.edit?.isFocus && !props.model.state.edit?.isActive,
        'section--active-in-navigator': props.model.state.edit?.isActive && editingStore.activeModel === props.model,
        'section--hide': props.model.state.hidden,
        ...animateClassList,
        [`${props.model.state.config.settings?.state.advanced?.advanced?.classname}`]:
          !!props.model.state.config?.settings?.state?.advanced?.advanced?.classname || false,
        'section--has-click': !!props.model.state.config?.settings?.state?.basic?.action
      };
    });

    const isPopup = campaignStore.model?.state.isPopup;
    const flowAnimatedIn = ref(false);

    const sectionStyles = computed<CSSProperties>(() => {
      const enterDuration = props.model.state.config.settings.state.advanced?.transitions?.enterDuration
        ? props.model.state.config.settings.state.advanced.transitions.enterDuration
        : 525;

      const enterDelay = props.model.state.config.settings.state.advanced?.transitions?.enterDelay
        ? props.model.state.config.settings.state.advanced.transitions.enterDelay
        : 0;

      let sectionTransition = {};

      if (
        sectionType.value + '' === ActiveElementTypes.SECTION &&
        props.model.state.config.settings.state.advanced?.transitions?.enter
      ) {
        sectionTransition = {
          animationDuration: enterDuration + 'ms',
          animationDelay: enterDelay + 'ms',
          transitionDelay: enterDelay + 'ms'
        };
      }

      let popupMarginLeft: string | undefined;
      let popupMarginRight: string | undefined;

      if (
        !(props.model instanceof PopoverModel) &&
        isPopup &&
        props.model.state.config.settings.state.elementStyling?.background?.general
      ) {
        popupMarginLeft = `${
          props.model.state.config.settings.state.elementStyling?.background?.general?.margin?.left ?? 0
        }px`;
        popupMarginRight = `${
          props.model.state.config.settings.state.elementStyling?.background?.general?.margin?.right ?? 0
        }px`;
      }

      const popupStyles = {
        ...(props.model.state.config.settings?.state.basic?.height?.width &&
          !props.model.state.config.settings?.state.basic?.height?.width.includes('%') && {
            width: props.model.state.config.settings.state.basic.height.width
          }),
        ...(popupMarginLeft && popupMarginLeft !== '' && { marginLeft: popupMarginLeft }),
        ...(popupMarginLeft && popupMarginLeft !== '' && { marginRight: popupMarginRight })
      };
      return {
        ...useLayoutSettings().applyInlineStyles(
          props.model.state.config.settings.state.elementStyling?.background?.general
        ),
        ...sectionTransition,
        ...(isPopup && { ...popupStyles }),
        ...{
          // TODO: This below should be revisited as it's not easy to decipher.
          // What the below fixes is that if flowpage is either in the middle of animating in, or fully animated in. Then updates on
          // the component doesn't set opacity to 0 again.
          opacity:
            ((flowAnimatedIn.value || sectionType.value !== SectionType.FLOWPAGE) && !shouldAnimateIn.value) ||
            isAnimatingIn.value
              ? '1'
              : '0'
        }
      };
    });

    const sectionCssStyle = computed(() => {
      let css = '';

      if (
        !props.model.state.config.settings.state.style?.content?.useDefaultTextColor &&
        props.model.state.config.settings.state.style?.content?.color &&
        typeof props.model.state.config.settings.state.style?.content?.color !== 'undefined'
      ) {
        css = `
          .${props.model.state.classIdentifier} .lf-text,
          .${props.model.state.classIdentifier} p,
          .${props.model.state.classIdentifier} a,
          .${props.model.state.classIdentifier} h1,
          .${props.model.state.classIdentifier} h2,
          .${props.model.state.classIdentifier} h3,
          .${props.model.state.classIdentifier} h4,
          .${props.model.state.classIdentifier} h5,
          .${props.model.state.classIdentifier} h6 {
            color: ${props.model.state.config.settings.state.style?.content?.color} !important;
          }
        `;
      }

      return css;
    });

    const showBackgroundStylesDiv = computed(() => {
      if (isPopover.value) {
        return true;
      }

      return !!props.model.state.config.settings.state.style.background?.color;
    });

    const backgroundColorStyles = computed<CSSProperties>(() => {
      const currentFlowPage = campaignStore.flowModel;
      const flowPageState = currentFlowPage?.state?.config?.settings?.state;

      if (isPopover.value && campaignStore.model) {
        const backgroundColor =
          props.model.state.config.settings.state.elementStyling?.background?.color?.backgroundColor;

        let generalBackgroundColor = campaignStore.model.state.layout?.backgroundColor;
        const customPopoverColorsEnabled = campaignStore.model.state.layout?.customPopoverColors; // Unclear if this should be used?
        const customPopoverGlobalColorsEnabled = campaignStore.model.state.globalLayout?.customPopoverColors; // Unclear if this should be used?
        let popoverBackgroundColor: string | undefined;

        if (customPopoverColorsEnabled) {
          generalBackgroundColor = campaignStore.model.state.layout?.popoverBackgroundColor;
        } else if (!customPopoverColorsEnabled && customPopoverGlobalColorsEnabled) {
          generalBackgroundColor = campaignStore.model.state.globalLayout?.popoverBackgroundColor;
        } else if (!customPopoverColorsEnabled && !customPopoverGlobalColorsEnabled && !generalBackgroundColor) {
          popoverBackgroundColor = campaignStore.model.state.globalLayout?.backgroundColor;
        }

        if (
          backgroundColor &&
          props.model.state.config.settings.state.style.background?.backgroundType !== BackgroundType.TRANSPARENT
        ) {
          return {
            backgroundColor
          };
        } else if (generalBackgroundColor && generalBackgroundColor.length > 0) {
          return {
            backgroundColor: generalBackgroundColor,
            opacity: 0.9
          };
        } else {
          return {
            backgroundColor: popoverBackgroundColor,
            opacity: 0.9
          };
        }
      }

      let backgroundColorStyle: CSSProperties = {};
      if (props.model.state.config.settings.state.style.background?.backgroundType !== BackgroundType.TRANSPARENT) {
        backgroundColorStyle = {
          ...props.model.state.config.settings.state.elementStyling?.background?.color,
          ...(props.model.state.config.settings.state.basic?.height &&
            props.model.state.config.settings.state.basic?.height?.layout === 'grid' && {
              width: props.model.state.config.settings.state.basic.height.width,
              ...(backgroundStyleWidth.value && {
                maxWidth: backgroundStyleWidth.value
              })
            })
        };
      }

      if (!props.model.state.config.hasGameflowAddon) {
        if (
          !props.model.state.config.settings.state.style.background?.backgroundToSection &&
          props.model.state.config.settings.state.style.background?.backgroundType !== BackgroundType.TRANSPARENT
        ) {
          return { ...backgroundColorStyle };
        }

        if (props.model.state.config.settings.state.style.background?.backgroundType === BackgroundType.SLIDER) {
          return {};
        }
      }

      if (props.model.state.config.hasGameflowAddon) {
        if (
          flowPageState?.style.background?.backgroundType === BackgroundType.SLIDER &&
          flowPageState?.style.background?.backgroundToSection
        ) {
          return { background: flowPageState?.style.background?.color };
        }

        if (
          flowPageState?.style.background?.backgroundType === BackgroundType.VIDEO &&
          flowPageState?.style.background?.backgroundToSection
        ) {
          return { background: flowPageState?.style.background?.color };
        }
      }

      if (
        flowPageState?.style.background?.backgroundToSection &&
        flowPageState?.style.background.backgroundType !== BackgroundType.TRANSPARENT
      ) {
        // if same style then return nothing
        if (
          props.model.state.config.settings.state.elementStyling?.background ===
          flowPageState?.elementStyling?.background
        ) {
          backgroundColorStyle = {};
        } else if (props.model.state.config.hasGameflowAddon) {
          switch (flowPageState?.style.background.backgroundType) {
            case 'image':
              // this is for background color for image
              backgroundColorStyle = {
                ...flowPageState?.elementStyling?.background?.color
              };
              break;

            case 'solid':
              backgroundColorStyle = {
                ...flowPageState?.elementStyling?.background?.color
              };
              break;
          }
        }
      }

      if (
        currentFlowPage &&
        flowPageState?.style.overlay?.overlayToSection &&
        flowPageState?.style.overlay.overlayType !== OverlayType.TRANSPARENT
      ) {
        if (
          props.model.state.config.settings.state.elementStyling.background?.overlay ===
          flowPageState?.elementStyling?.background?.overlay
        ) {
          if (
            !flowPageState?.style.background?.backgroundToSection &&
            props.model.state.config.settings.state.style.background?.backgroundType !== BackgroundType.TRANSPARENT
          ) {
            backgroundColorStyle = {
              ...props.model.state.config.settings.state.elementStyling?.background?.color,
              width: flowPageState?.basic?.height?.width,
              ...(backgroundStyleWidth.value && {
                maxWidth: backgroundStyleWidth.value
              })
            };
          } else {
            backgroundColorStyle = {};
          }
        }
      } else if (
        flowPageState?.style.overlay?.overlayToSection &&
        flowPageState?.style?.overlay?.overlayType !== OverlayType.TRANSPARENT
      ) {
        backgroundColorStyle = {
          ...flowPageState?.elementStyling?.background?.overlay
        };
      }

      return { ...backgroundColorStyle };
    });

    const videoBackgroundStyles = computed<CSSProperties>(() => {
      const elementStyling = props.model.state.config.settings.state.elementStyling;

      return {
        width: videoWidth.value + '%',
        ...(elementStyling && elementStyling?.background?.video)
      };
    });

    const enterAnimation = (): FlowAnimation | undefined => {
      if (isFlowPage.value || isPopover) {
        const transition = props.model.state.config.settings.state.advanced?.transitions;
        const defaultAnimation = isFlowPage.value ? TransitionEnterType.FADE_IN : TransitionEnterType.FADE_IN_UP;

        let transitionName = transition && transition.enter !== undefined ? transition.enter : defaultAnimation;

        // Popup related. If window height exceeds the section height we need to fade it down from the top.
        if (
          campaignStore.model?.state.isPopup &&
          inIframe() &&
          sectionRef.value &&
          sectionRef.value instanceof HTMLElement
        ) {
          const windowHeight = utilityStore.windowHeight;

          if (sectionRef.value.offsetHeight > windowHeight) {
            transitionName = TransitionEnterType.FADE_IN_DOWN;
          }
        }

        return {
          name: transitionName,
          duration:
            transition && transition.enter !== undefined && transition.enterDuration ? transition.enterDuration : 525,
          delay: transition && transition.enter !== undefined && transition.enterDelay ? transition.enterDelay : 0
        };
      }
    };

    const leaveAnimation = (): FlowAnimation | undefined => {
      if (isFlowPage.value || isPopover) {
        const transition = props.model.state?.config.settings?.state?.advanced?.transitions;
        const defaultAnimation = isFlowPage.value ? TransitionEnterType.FADE_OUT : TransitionEnterType.FADE_OUT_DOWN;

        return {
          name: transition && transition.leave !== undefined ? transition.leave : defaultAnimation,
          duration:
            transition && transition.leave !== undefined && transition.leaveDuration ? transition.leaveDuration : 525,
          delay: transition && transition.leave !== undefined && transition.leaveDelay ? transition.leaveDelay : 0
        };
      }
    };

    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const currentInstance = getCurrentInstance();

    const onClick = (event: Event) => {
      // Sections that aren't meant to be interactable are fixed with an ID 0.
      // This is used for popups where we work with a static section that cannot be editted.
      if (props.model.state.id === 0) {
        return;
      }

      if (
        event &&
        (campaignStore.model?.state.isEditModeActive ||
          props.model.state.config.settings.state.basic?.action?.state.type)
      ) {
        if (campaignStore.fictiveFlowPage) {
          let gameplayModel: AddonGameplayModel | undefined;

          campaignStore.model?.state.flowPages.forEach((pageModel) => {
            pageModel.getAddons<AddonGameplayModel>('gameplay').forEach((flowModel) => {
              gameplayModel = flowModel;
            });
          });

          if (gameplayModel) {
            editingStore.setActiveModel(gameplayModel);
          }
        } else if (
          campaignStore.model?.state.isEditModeActive &&
          editingStore.activeTabCategory === SectionType.SECTION
        ) {
          editingStore.setActiveModel(props.model);
        }
        event.stopPropagation();

        // we force to hide the context menu when we are clicking outside of it
        // we have to do this since we are using "event.stopPropagation"
        editingStore.hideContextMenu();
      }

      toggleActive();

      if (props.model.state.config.settings.state.basic?.action) {
        props.model.state.config.settings.state.basic.action.triggerAction(props.model);
      }
    };

    const shouldDisableRedirectOnFirstFlowPage = () => {
      // Disable redirect on first flowPage
      const campaignState = campaignStore.model?.state;
      const firstFlowPage = campaignState?.flowPages[0];
      const type = props.model.state.config.settings.state.redirect?.state.type;

      return firstFlowPage && firstFlowPage.state.id === props.model.state.id && isDemo && type === ActionType.URL;
    };

    onUpdated(() => {
      setVideoWidth();
    });

    // InterSectionObserver data
    let playedEnter = false;

    const onAnimationEnd = (event: Event) => {
      if (
        event instanceof AnimationEvent && // Ensure the event is of type AnimationEvent
        event.target instanceof HTMLElement &&
        props.model.state.classIdentifier &&
        event.target.classList.contains(props.model.state.classIdentifier)
      ) {
        sectionRef.value?.removeEventListener('animationend', onAnimationEnd);
        hasAnimatedIn.value = true;
      }
    };

    const setPositionerWidth = () => {
      if (!props.model.state?.config?.settings?.state.basic?.height?.width) {
        return;
      }

      if (!sectionRef.value) {
        return;
      }

      if (!sectionRef.value.parentElement || !sectionRef.value.parentElement.classList.contains('positioner')) {
        return;
      }

      if (props.model.state.config.settings.state.basic.height.width.includes('%')) {
        sectionRef.value.parentElement.style.width = props.model.state.config.settings.state.basic.height.width;
      } else {
        sectionRef.value.parentElement.style.width = '';
      }
    };

    let handledTracking = false;

    const handleTracking = async () => {
      const routeName = (route?.name as string).replace(' ', '');

      if (
        routeName !== 'Campaigndemo' &&
        isFlowPage.value &&
        !isFirstFlowPage() &&
        !props.model.state.programmatic &&
        !handledTracking
      ) {
        await useAnalytics().handlePageView(props.model.id);

        handledTracking = true;
      }
    };

    onMounted(async () => {
      setPositionerWidth();

      sectionRef.value?.addEventListener('animationend', onAnimationEnd);
      if (props.model.state.config.settings.state.redirect?.state && !shouldDisableRedirectOnFirstFlowPage()) {
        await handleTracking();
        props.model.state.config.settings.state.redirect.triggerAction(props.model);
      }

      // LeadScore
      if (
        (campaignStore.model?.state.config?.leadScoreEnabled &&
          sectionRef.value?.classList.contains('flow__page-add')) ||
        sectionRef.value?.classList.contains('flow__page')
      ) {
        const flowPageId = props.model.getSection().id;

        if (flowPageId !== -1) {
          useLeadScore().track(
            {
              inSession: false,
              object: 'FLOW_PAGE',
              object_value: flowPageId,
              distinct: true
            },
            false,
            () => {
              failedTrackedAttempts.value++;
            }
          );
        }
      }

      setVideoWidth();

      if (sectionRef.value instanceof HTMLElement && sectionType.value === SectionType.SECTION) {
        props.model.setSectionRefElement(sectionRef.value);

        // only section should have InterSectionObserver
        if (shouldAnimateIn.value) {
          if ('IntersectionObserver' in window) {
            // observe if section is in intersection
            const observer = new IntersectionObserver(([entry]) => {
              if (entry && entry.isIntersecting && !playedEnter && sectionRef.value) {
                observer.unobserve(sectionRef.value);
                playedEnter = true;

                // Play animation
                animateIn.value = true;
                shouldAnimateIn.value = false;
              }
            });

            observer.observe(sectionRef.value);
          } else {
            // Play animation
            animateIn.value = true;
            shouldAnimateIn.value = false;
          }
        }
      }

      if (isSection.value && !campaignStore.mountedSections.includes(props.model.id)) {
        campaignStore.mountedSections.push(props.model.id);
      } else if (isFlowPage.value && !campaignStore.mountedFlowpages.includes(props.model.id)) {
        campaignStore.mountedFlowpages.push(props.model.id);
      }
    });

    onUnmounted(async () => {
      if (isSection.value && campaignStore.mountedSections.includes(props.model.id)) {
        campaignStore.mountedSections.splice(campaignStore.mountedSections.indexOf(props.model.id), 1);
      } else if (isFlowPage.value && campaignStore.mountedFlowpages.includes(props.model.id)) {
        campaignStore.mountedFlowpages.splice(campaignStore.mountedFlowpages.indexOf(props.model.id), 1);
      }
    });

    /**
     * If in doubt about what this does, or you need to modify this function, speak to Dannie before
     * */
    nextTick(async () => {
      if (currentInstance?.vnode) {
        await recursivelyWaitForPromises(currentInstance.vnode, 'onBeforeEnter', 3);
      }

      await backgroundPreloadPromise;

      if (readyPromiseResolve) {
        isReady.value = true;
        readyPromiseResolve();
      }

      props.model.state.config.settings.state.basic?.action?.startAutoTrigger();

      if (sectionRef.value) {
        events.emit('sectionReady', sectionRef.value, props.model);
      }
    });

    // Scroll to section if hash is set with a value that matches the section ID to emulate browser normal anchor scrolling
    if (
      window.location.hash &&
      props.model.state.config.settings.state.advanced.advanced?.elementId &&
      window.location.hash.substring(1) === props.model.state.config.settings.state.advanced.advanced?.elementId
    ) {
      const campaignReadyWatcher = watch(
        () => campaignStore.model?.state.contentReady,
        (value) => {
          if (value) {
            campaignReadyWatcher();

            sectionRef.value?.scrollIntoView();
          }
        }
      );
    }

    watch(
      () => failedTrackedAttempts.value,
      async () => {
        if (failedTrackedAttempts.value > 0 && failedTrackedAttempts.value <= 3) {
          const response = await useLeadScore().sendTracked();
          if (!response) {
            failedTrackedAttempts.value++;
          }
        }
      }
    );

    watch(
      () => props.model.state.edit?.isActive,
      (isActive) => {
        if (isActive && campaignStore.model?.state.isEditModeActive && sectionRef.value) {
          sectionRef.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    );

    // Watcher to re-calculate section background when flow page change as
    // flow pages can overwrite the background image of the section.
    watch(
      () => campaignStore.flowIndex,
      async () => {
        await nextTick();

        if (props.model.getAddons<AddonGameflowModel>('gameflow').length === 1) {
          backgroundImageStyleProcess();
        }
      }
    );

    watch(
      () => props.model.state?.config?.settings?.state.basic?.height?.width,
      () => {
        setPositionerWidth();
      },
      {
        immediate: true
      }
    );

    return {
      isCssEnabled,
      backgroundHasBorder,
      backgroundStyleWidth,
      showContextMenu,
      setFocus,
      removeFocus,
      onClick,
      sectionStyles,
      sectionBackgroundVideoStyles,
      sectionBackgroundImageSlidesStyles,
      sectionOverlayStyles,
      gameSectionColorOverlay,
      sectionClasses,
      sectionRef,
      videoWidth,
      gridStyles,
      videoRatio,
      sectionCssStyle,
      backgroundColorStyles,
      backgroundImageStyle,
      videoBackgroundStyles,
      state: props.model.state,
      ContentType,
      SectionType,
      showBackgroundStylesDiv,
      removeActive,
      enterAnimation,
      leaveAnimation,
      editingStore,
      campaignStore,
      isPopup,
      onBeforeEnter: async () => {
        await readyPromise;
      },
      onBeforeLeave: async () => {
        events.emit('sectionBeforeLeave', props.model);
      },
      onBeforeEnterAnimation: async () => {
        events.emit('sectionBeforeEnterAnimation', props.model);
        isAnimatingIn.value = true;
      },
      onBeforeLeaveAnimation: async () => {
        events.emit('sectionBeforeLeaveAnimation', props.model);
      },
      onAfterLeave: async () => {
        events.emit('sectionAfterLeave', props.model);
      },
      onAfterEnter: async () => {
        if (props.model.state.config.settings.state.redirect?.state && !shouldDisableRedirectOnFirstFlowPage()) {
          return;
        }

        handleTracking();
        flowAnimatedIn.value = true;
      }
    };
  }
});
</script>

<style lang="scss">
.content__item--text {
  h2 {
    margin-left: -0.2rem;
    margin-bottom: 1.5rem;
    margin-top: -0.5rem;
  }
}

.flow > .row {
  opacity: 0;
}

body.site--editmode-on {
  .section {
    &--edit-focus {
      &:before {
        border-style: dashed;
        border-color: $edit-border-hover-color;
      }
      &:after {
        @include lf-title();
        background: rgba(#878787, 0.9);
      }
    }

    &--active-in-navigator {
      &:before {
        border-color: $edit-border-color;
      }

      &:after {
        @include lf-title();
        color: #fff;
        width: 100px;
        background-color: $edit-border-color;
      }
    }
  }
}

.section {
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  min-height: 1px;

  &.animated:not(.flow__page--leave) {
    opacity: 1;
  }

  &--hide {
    display: none !important;
  }

  &.transition {
    display: flex;
  }

  &.flow__page {
    &--leave {
      position: absolute;
      top: 0;
      left: 0;
    }

    .no-gutters .flow--not-ejected > & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .edit-mode-on & {
    overflow: visible;
  }

  > .section__content {
    flex-basis: auto;
  }

  &--has-click {
    cursor: pointer;
  }

  &--no-sections {
    text-align: center;

    .section__content {
      position: static !important;
    }

    .content__item--text .row {
      max-width: none !important;
    }
  }

  &--align-center {
    justify-content: center;

    .section__content {
      flex-grow: 0;
    }
  }

  &--align-end {
    justify-content: flex-end;

    .section__content {
      flex-grow: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;

    &-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .section--background-center > & {
      left: 50%;
      transform: translateX(-50%);
    }

    video {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      min-height: 100%;
      min-width: 100%;
      height: auto;
      width: 100%;
      object-fit: cover;
    }

    .video-embed-wrap {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      &--not-ratio {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 100%;
        transform: translateX(-50%);
      }
    }

    iframe,
    .video-embed-element {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__background-color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    .section--background-center > & {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;

    .section--background-center > & {
      left: 50%;
      transform: translateX(-50%);
    }

    .row--has-video & {
      z-index: 0;
    }
  }

  &__html-elements {
    display: inline;
    pointer-events: none;
  }

  .content__item--text .row:first-child {
    &:before {
      height: 0;
    }
  }

  .content__item--text .row:last-child {
    &:after {
      height: 0;
    }
  }

  &--shadow {
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.4);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: -1;
    transition: opacity 500ms linear;
  }

  &__overlay-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 1;
    transition: opacity 500ms linear;
    transform: translate(0, -50%);
    z-index: 3;
    max-width: 112.8rem;
    margin: 0 auto;
    text-align: center;
    padding: 0 2rem;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    color: #000;
    font-size: 2.5rem;
    line-height: 4.2rem;
    text-align: center;
    cursor: pointer;
    z-index: 2;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &--has-addon-menu .section__content {
    z-index: 3;
  }
}

.campaign__page > .section {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.row--has-video > .section__content {
  z-index: 1;
}
</style>
