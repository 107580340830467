<template>
  <component :is="tag" v-if="isLegacyHeadlineCampaign" v-html="processedReplacementTags" />
  <component :is="tag" v-else-if="tag">
    <template v-for="(line, index) in processedHtmlTag" :key="index">
      <slot name="line" :line="line">
        {{ line }}
      </slot>
      <br v-if="index < processedHtmlTag.length - 1" />
    </template>
  </component>
  <template v-else>
    {{ processedReplacementTags }}
  </template>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { applyReplacementTags } from '@/src/utilities/Utilities';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { isDevPlayableDomain, isLocalhostDomain } from '@/src/utilities/Url';

type TagTypes = 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';

export default defineComponent({
  name: 'ApplyReplacementTags',
  props: {
    html: {
      type: String,
      required: true
    },
    extraReplacementTags: {
      type: Object as PropType<ReplacementTags>,
      required: false
    },
    tag: {
      type: String as PropType<TagTypes>,
      required: false
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();

    // We allow older campaigns to render html in the headline tag, so we have a fixed cutoff for newer that is 139641
    const isLegacyHeadlineCampaign = computed(() => {
      // for dev / local env we don't want to check since the campaigns should be treated as new
      if (props.tag && !isLocalhostDomain()) {
        const hasHeadlineTag = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(props.tag);

        if (isDevPlayableDomain()) {
          return campaignStore.model?.state.id && campaignStore.model.state.id < 99520 && hasHeadlineTag;
        }

        // 139641 is used here as a threshold ID to determine "old" campaigns
        return campaignStore.model?.state.id && campaignStore.model.state.id < 139641 && hasHeadlineTag;
      }
      return false;
    });

    const processedReplacementTags = computed(() => {
      return applyReplacementTags(props.html, props.extraReplacementTags);
    });

    const processedHtmlTag = computed<string[]>(() => {
      const lines = processedReplacementTags.value.split(/<br\s*\/?>/gi);
      if (props.tag) {
        return lines.map((line) => line);
      }
      return [processedReplacementTags.value];
    });

    return { processedHtmlTag, isLegacyHeadlineCampaign, processedReplacementTags };
  }
});
</script>
