<template>
  <div ref="columnRef" :class="columnClasses">
    <component is="style" v-if="model.state.customCss && isCssEnabled" type="text/css">
      {{ model.state.customCss.state?.code }}
    </component>
    <component is="style" v-if="columnCssStyle" type="text/css">
      {{ columnCssStyle }}
    </component>

    <component is="style" v-if="columnTagStyle" type="text/css">
      {{ columnTagStyle }}
    </component>
    <div
      :id="columnId"
      v-click-away="removeActive"
      class="grid__col-container"
      :style="columnStyling"
      :class="columnContainerClasses"
      @mouseover.stop="setFocus"
      @mouseout.stop="removeFocus"
      @click.right="showContextMenu($event, model, model.state.edit?.isActive)"
      @click="toggleActive($event)"
    >
      <div
        v-if="campaignState?.isEditModeActive && sectionType === editingStore.activeTabCategory"
        class="edit-title"
        style="display: none"
      >
        {{ model.state.label }}
      </div>

      <DeleteElement
        v-if="
          model.state.edit?.isActive &&
          campaignState?.isEditModeActive &&
          model.getSection().getSectionType() === editingStore.activeTabCategory &&
          model.row.state.columns.length > 1
        "
        :model="model"
      />

      <div
        v-if="isBackgroundEnabled"
        class="grid__col-container-bg-color"
        :style="model.state.settings?.state.elementStyling?.background?.color"
      ></div>

      <div
        v-if="model.state.settings?.state.config.isBackgroundImageEnabled"
        class="grid__col-container-bg"
        :style="backgroundImageStyle"
      ></div>

      <div
        v-if="isColumnOverlayEnabled"
        class="grid__col-container-bg-overlay"
        :style="model.state.settings?.state?.elementStyling?.background?.overlay"
      ></div>

      <div
        v-if="isColumnGradientEnabled"
        class="grid__col-container-bg-overlay"
        :style="model.state.settings?.state?.elementStyling?.background?.overlay"
      ></div>

      <div class="grid__col-addons">
        <AddonWrap v-for="addon in addons" :key="addon.state.modelId" :addon="addon" />
      </div>
    </div>

    <LFNavigatorContentAdd
      v-if="
        (campaignState?.isEditModeActive && model.state.edit?.isActive) ||
        (campaignState?.isEditModeActive &&
          isColumnEmpty() &&
          model.getSection().getSectionType() === editingStore.activeTabCategory) ||
        (campaignState?.isEditModeActive && model.state.edit?.hasActiveAddon) ||
        (campaignState?.isEditModeActive && hasModalOpen)
      "
      :column-model="model"
      @modalOpen="hasModalOpen = true"
      @modalClose="hasModalOpen = false"
    />
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import type { ContentAddonType } from '@/src/components/layout/column/ColumnModel';
import type ColumnModel from '@/src/components/layout/column/ColumnModel';
import AddonWrap from '@/src/components/addons/AddonWrap.vue';
import useNavigator from '@/src/hooks/useNavigator';
import DeleteElement from '@/src/components/components/editing/DeleteElement.vue';
import LFNavigatorContentAdd from '@/src/components/components/editing/Navigator/LFNavigatorContentAdd.vue';
import { BackgroundType, OverlayType } from '@/src/typings/enums/enums';
import type { ClassList } from '@/src/typings/types/types';
import { applyReplacementTags } from '@/src/utilities/Utilities';
import useLayoutSettings from '@/src/components/layout/hooks/useInlineLayoutStyles';
import { useCampaignStore } from '@/src/store/campaign';
import { useEditingStore } from '@/src/store/editing';

export default defineComponent({
  name: 'Column',
  components: {
    LFNavigatorContentAdd,
    DeleteElement,
    AddonWrap
  },
  props: {
    model: {
      type: Object as PropType<ColumnModel>,
      required: true
    }
  },
  setup(props) {
    const columnRef = ref<HTMLElement | null>(null);

    const shouldAnimateIn = ref(
      props.model.state.settings?.state.advanced?.transitions?.enter &&
        props.model.state.settings?.state.advanced.transitions.enter !== 'none'
    );

    const animateIn = ref(false);
    const hasAnimatedIn = ref(false);
    const hasModalOpen = ref(false);
    const { setFocus, removeFocus } = useNavigator(props.model);

    const campaignStore = useCampaignStore();
    const editingStore = useEditingStore();
    const campaignState = campaignStore.model?.state;

    const sectionType = computed(() => props.model.getSection().getSectionType());

    const isCssEnabled = computed(() => {
      return campaignStore.enableCSS;
    });

    const columnCssStyle = computed(() => {
      let css = '';

      if (
        !props.model.state.settings.state.style.content?.useDefaultTextColor &&
        props.model.state.settings.state.style?.content?.color &&
        typeof props.model.state.settings.state.style?.content?.color !== 'undefined'
      ) {
        css = `
          .section .grid__row .${props.model.state.classIdentifier} .lf-text,
          .section .grid__row .${props.model.state.classIdentifier} p,
          .section .grid__row .${props.model.state.classIdentifier} a,
          .section .grid__row .${props.model.state.classIdentifier} h1,
          .section .grid__row .${props.model.state.classIdentifier} h2,
          .section .grid__row .${props.model.state.classIdentifier} h3,
          .section .grid__row .${props.model.state.classIdentifier} h4,
          .section .grid__row .${props.model.state.classIdentifier} h5,
          .section .grid__row .${props.model.state.classIdentifier} h6 {
            color: ${props.model.state.settings.state.elementStyling.background?.general?.color} !important;
          }
        `;
      }

      return css;
    });

    const columnClick = (event?: Event) => {
      if (event && props.model.state.action?.state.type) {
        event.stopPropagation();
      }

      props.model.state.action?.triggerAction(props.model, props.model.replacementTags);
    };

    const toggleActive = (event: Event) => {
      if (campaignState?.isEditModeActive && sectionType.value === editingStore.activeTabCategory) {
        event.stopPropagation();

        editingStore.setActiveModel(props.model);
      } else {
        columnClick(event);
      }
    };

    const showContextMenu = (event: MouseEvent, model: ColumnModel, isActive: boolean | undefined) => {
      if (campaignState?.isEditModeActive) {
        event.preventDefault();
        event.stopPropagation();
        if (typeof isActive === 'boolean') {
          editingStore.showContextMenu(isActive, event, model);
        }
      }
    };

    const removeActive = () => {
      if (
        !hasModalOpen.value &&
        campaignState?.isEditModeActive &&
        (props.model as ColumnModel).getSection().getSectionType() === editingStore.activeTabCategory
      ) {
        editingStore.removeActiveModel();
      }
    };

    const isColumnEmpty = () => {
      return props.model.state.addons.length === 0;
    };

    const isBackgroundEnabled = computed(() => {
      return props.model.state.settings?.state.style?.background?.backgroundType !== BackgroundType.TRANSPARENT;
    });

    const columnTagStyle = computed(() => {
      // check for overwriting default color
      if (
        !props.model.state.settings.state.style.content?.useDefaultTextColor &&
        props.model.state.settings.state.style.content?.color &&
        props.model.state.settings.state.style.content?.color !== ''
      ) {
        const classIdentifier = `.${props.model.state.classIdentifier}`;
        return `.section .grid__row ${classIdentifier} .lf-text,
                .section .grid__row ${classIdentifier} p,
                .section .grid__row ${classIdentifier} a,
                .section .grid__row ${classIdentifier} h1,
                .section .grid__row ${classIdentifier} h2,
                .section .grid__row ${classIdentifier} h3,
                .section .grid__row ${classIdentifier} h4,
                .section .grid__row ${classIdentifier} h5,
                .section .grid__row ${classIdentifier} h6 { color: ${props.model.state.settings.state.style.content?.color} !important; }
              `;
      }
      return undefined;
    });

    const isColumnOverlayEnabled = computed(() => {
      return (
        props.model.state.settings?.state.elementStyling?.background?.overlay &&
        props.model.state.settings?.state.style?.overlay?.overlayType === OverlayType.SOLID
      );
    });

    const isColumnGradientEnabled = computed(() => {
      return (
        props.model.state.settings?.state.elementStyling?.background?.overlay &&
        props.model.state.settings?.state.style?.overlay?.overlayType === OverlayType.GRADIENTS
      );
    });

    const columnClasses = computed(() => {
      return {
        [props.model.state.classIdentifier]: true,
        'grid__col--empty': isColumnEmpty(),
        [`grid__col col-md-${props.model.state.size}`]: !!props.model.state.size,
        'column--edit-focus': props.model.state.edit?.isFocus && !props.model.state.edit?.isActive,
        'column--active-in-navigator': props.model.state.edit?.isActive,
        'grid__col--addon-active-in-navigator':
          props.model.state.edit?.hasActiveAddon &&
          campaignState?.edit?.enabled &&
          campaignState.isEditModeActive &&
          editingStore.activeTabCategory === sectionType.value
      };
    });

    const columnContainerClasses = computed(() => {
      const animateClassList: ClassList = {};
      if (animateIn.value && campaignState?.contentReady && !hasAnimatedIn.value) {
        animateClassList[`animated ${props.model.state.settings?.state.advanced?.transitions?.enter}`] = true;
      }

      return {
        [props.model.state.settings?.state.advanced?.advanced?.classname ?? '']:
          !!props.model.state.settings?.state.advanced?.advanced?.classname,
        'grid__col-container--has-click': !!props.model.state.action?.state,
        ...animateClassList
      };
    });

    const columnId = computed(() => {
      return props.model.state.settings?.state.advanced?.advanced?.elementId;
    });

    const columnStyling = computed(() => {
      const enterDuration = props.model.state.settings?.state.advanced?.transitions?.enterDuration
        ? props.model.state.settings?.state.advanced.transitions.enterDuration
        : 525;
      const enterDelay = props.model.state.settings?.state.advanced?.transitions?.enterDelay
        ? props.model.state.settings?.state.advanced.transitions.enterDelay
        : 0;

      let columnTransition: CSSProperties = {};
      if (props.model.state.settings?.state.advanced?.transitions?.enter) {
        columnTransition = {
          animationDuration: enterDuration + 'ms',
          animationDelay: enterDelay + 'ms',
          transitionDelay: enterDelay + 'ms'
        };
      }

      const columOpacity: CSSProperties = { opacity: 1 };

      if (shouldAnimateIn.value) {
        columOpacity.opacity = 0;
      }

      return {
        ...useLayoutSettings().applyInlineStyles(
          props.model.state?.settings?.state?.elementStyling?.background?.general
        ),
        ...columOpacity,
        ...columnTransition
      };
    });

    // Background image style is ejected out in a computed to properly handle
    // replacement of replacement tags, such as #date#.
    const backgroundImageStyle = computed<CSSProperties>(() => {
      let style: CSSProperties = {};

      if (props.model.state.settings?.state.elementStyling?.background?.image) {
        style = { ...props.model.state.settings.state.elementStyling.background?.image };

        if (style.backgroundImage) {
          style.backgroundImage = applyReplacementTags(style.backgroundImage);
        }
      }

      return style;
    });

    const addons = computed<ContentAddonType[]>(() => {
      return props.model.state.addons.filter((addon) => {
        return addon.state?.genericAdvancedSettings?.state.visibilityConditions
          ? addon.state.genericAdvancedSettings.state.visibilityConditions.check()
          : true;
      });
    });

    watch(
      () => props.model.state.edit?.isActive,
      (isActive) => {
        if (isActive && campaignState?.isEditModeActive) {
          hasModalOpen.value = false;
          props.model.row.section.state.edit?.sectionRef?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    );

    // InterSectionObserver data
    let playedEnter = false;

    const onAnimationEnd = (event: AnimationEvent) => {
      if (
        event.target instanceof HTMLElement &&
        props.model.state.classIdentifier &&
        event.target?.classList.contains(props.model.state.classIdentifier)
      ) {
        columnRef.value?.removeEventListener('animationend', onAnimationEnd);
        hasAnimatedIn.value = true;
      }
    };

    onMounted(() => {
      columnRef.value?.addEventListener('animationend', onAnimationEnd);
      props.model.state.action?.startAutoTrigger(columnClick);

      if (columnRef.value) {
        // only section should have InterSectionObserver
        if (shouldAnimateIn.value) {
          if ('IntersectionObserver' in window) {
            // observe if section is in intersection
            const observer = new IntersectionObserver(([entry]) => {
              if (entry && entry.isIntersecting && !playedEnter && columnRef.value) {
                // @ts-ignore
                observer.unobserve(columnRef.value);
                playedEnter = true;

                // Play animation
                animateIn.value = true;
                shouldAnimateIn.value = false;
              }
            });
            // @ts-ignore
            observer.observe(columnRef.value);
          } else {
            // Play animation
            animateIn.value = true;
            shouldAnimateIn.value = false;
          }
        }
      }
    });

    return {
      isCssEnabled,
      sectionType,
      columnRef,
      editingStore,
      campaignState,
      isBackgroundEnabled,
      isColumnGradientEnabled,
      isColumnOverlayEnabled,
      isColumnEmpty,
      columnClasses,
      columnContainerClasses,
      columnId,
      columnStyling,
      backgroundImageStyle,
      columnTagStyle,
      columnClick,
      showContextMenu,
      setFocus,
      removeFocus,
      toggleActive,
      removeActive,
      hasModalOpen,
      columnCssStyle,
      addons
    };
  }
});
</script>

<style lang="scss">
.site--editmode-on {
  .grid__col {
    .grid__col-container {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid transparent;
        z-index: 10;
        pointer-events: none;
      }
    }

    &--addon-active-in-navigator {
      & > .grid__col-container {
        &:before {
          border-color: $edit-border-color;
        }
      }
    }

    &--empty {
      min-height: 120px;
      .grid__col-container {
        border: 1px dashed hsla(0, 0%, 80%, 0.5);
      }
      .content-item__add {
        left: 50%;
        top: 50%;
        margin-left: -12px;
        margin-top: -12px;
      }
    }
  }
  .column {
    .edit-title {
      display: none;
    }
    &--edit-focus {
      & > .grid__col-container {
        &:before {
          border-style: dashed;
          border-color: $edit-border-hover-color;
        }
        & > .edit-title {
          @include lf-title();
          background-color: rgba(#878787, 0.9);
        }
      }
    }
    &--active-in-navigator {
      & > .grid__col-container {
        &:before {
          border-color: $edit-border-color;
        }
        & > .edit-title {
          @include lf-title();
          background-color: $edit-border-color;
          color: #fff;
          z-index: 100;
        }
      }
    }
  }
}
</style>
