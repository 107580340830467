<template>
  <div class="addon lf-headline">
    <component :is="asyncAddonLfHeadlineEditor" v-if="isEditModeActive && isDemo && isEditorReady" :model="model" />

    <ApplyReplacementTags
      v-if="!editorLoaded && hasGradient"
      :tag="state.type"
      :html="text"
      :style="state?.elementStyling?.inline"
      :extra-replacement-tags="model.column?.replacementTags"
      class="lf-headline__content"
    >
      <template #line="{ line }">
        <span :style="state?.elementStyling?.gradient">
          {{ line }}
        </span>
      </template>
    </ApplyReplacementTags>

    <ApplyReplacementTags
      v-else-if="!editorLoaded"
      :tag="state.type"
      :html="text"
      :extra-replacement-tags="model.column?.replacementTags"
      :style="state?.elementStyling?.inline"
      class="lf-headline__content"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineAsyncComponent, nextTick, ref, watch } from 'vue';
import { computed, defineComponent, onMounted } from 'vue';
import type { AddonLfHeadlineModel } from '@/src/components/addons/lf-headline/Model';
import useFontObserver from '@/src/hooks/useFontObserver';
import ApplyReplacementTags from '@/src/components/ApplyReplacementTags.vue';
import { useCampaignStore } from '@/src/store/campaign';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'AddonLfHeadline',
  components: {
    ApplyReplacementTags
  },
  props: {
    model: {
      type: Object as PropType<AddonLfHeadlineModel>,
      required: true
    }
  },
  setup(props) {
    const state = props.model.state;
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();
    const editorLoaded = ref(false);

    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const isEditorReady = ref(true);

    const isEditModeActive = computed<boolean>(() => {
      return campaignStore.model?.state.isEditModeActive ?? false;
    });

    const hasGradient = computed(() => {
      return state.layout?.typography?.gradientOne && state.layout?.typography?.gradientTwo;
    });

    const isDemo = computed(() => {
      return utilityStore.url.includes('/campaign/view/demo');
    });

    const text = computed<string>(() => {
      const fallbackContent = 'Lorem ipsum dolor sit amet';

      if (state.text === '' || state.text === undefined) {
        return fallbackContent;
      }

      return state.text;
    });

    const asyncAddonLfHeadlineEditor = defineAsyncComponent({
      loader: () =>
        import('@/src/components/addons/lf-headline/Editor.vue').then((component) => {
          editorLoaded.value = true;
          return component;
        })
    });

    watch(
      () => isEditModeActive.value,
      () => {
        editorLoaded.value = isEditModeActive.value ?? false;
      }
    );

    watch(
      () => state.type,
      async () => {
        isEditorReady.value = false;
        await nextTick();
        await nextTick();
        isEditorReady.value = true;
      }
    );

    onMounted(async () => {
      const { isFontLoaded } = useFontObserver();

      if (state.layout?.typography?.fontFamily) {
        await isFontLoaded(state.layout.typography.fontFamily);
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }
    });

    return {
      hasGradient,
      editorLoaded,
      isEditorReady,
      isDemo,
      state,
      text,
      isEditModeActive,
      asyncAddonLfHeadlineEditor,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>
