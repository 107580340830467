import type { CSSProperties } from 'vue';
import { getAdminUiEndpoint } from '@/src/utilities/Url';
import { useCampaignStore } from '@/src/store/campaign';
import type {
  AddonRegistrationAppearanceData,
  AddonRegistrationAppearanceDevice,
  AddonRegistrationData,
  RedirectOptions
} from '@/src/components/addons/registration/Data';
import { AlignContentType, ButtonStyleType, FieldInputType, SectionType } from '@/src/typings/enums/enums';
import type { ValidationMessageType } from '@/src/components/addons/registration/enums';
import { InputStyleType } from '@/src/components/addons/registration/enums';
import { isValidValue, transformMeasurementToNumber } from '@/src/utilities/Utilities';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import useDevice from '@/src/hooks/useDevice';
import type { FormElementModel } from '@/src/components/addons/registration/types';
import { FormElementMapping } from '@/src/components/addons/registration/types';
import useAxios from '@/src/hooks/useAxios';

interface GaEvent {
  enable: boolean | null;
  category?: string | null;
  action?: string | null;
  label?: string | null;
  value?: string | null;
}

interface RegistrationRedirectState {
  options?: RedirectOptions;
  url?: string;
}

interface RegistrationFormLayoutState {
  width?: number;
  align?: AlignContentType;
  typeIsPixels: boolean;
  typeIsPercent: boolean;
}

interface RegistrationConfirmationState {
  message?: string;
}

interface RegistrationButtonState {
  enabled?: boolean;
  label: string;
  type: ButtonStyleType;
  alignment: AlignContentType;
  backgroundColor?: string;
  textColor?: string;
}

interface RegistrationAppearanceDeviceState {
  fontSize?: number;
  fontSizeInPixels?: boolean;
  fontSizeInPercent?: boolean;
  backgroundColor?: string;
  strokeColor?: string;
  textColor?: string;
  placeholderColor?: string;
  labelColor?: string;
  checkColor?: string;
  checkBackground?: string;
  validationColor?: string;
}

interface RegistrationAppearanceState extends RegistrationAppearanceDeviceState {
  inputStyle?: InputStyleType;
  elementSpace?: number;
  formStyle?: {
    validationMessageType?: ValidationMessageType;
    backgroundColor?: string;
    strokeColor?: string;
  };
}

interface AddonRegistrationState extends AddonModelState {
  isForm?: boolean;
  formId?: string;
  campaignId?: number;
  disableFormAutoComplete: boolean;
  fields?: FormElementModel[];
  ga?: GaEvent;
  form?: RegistrationFormLayoutState;
  redirect?: RegistrationRedirectState;
  confirmation?: RegistrationConfirmationState;
  button?: RegistrationButtonState;
  endpoint?: string;
  appearance?: RegistrationAppearanceState;
  skipRegistration?: boolean;
  disableAutocomplete?: boolean;
  disableCookies?: boolean;
  elementStyling?: {
    inline?: CSSProperties;
    container?: CSSProperties;
    cssStyle?: string;
    buttonContainerStyle?: CSSProperties;
  };
}

export class AddonRegistrationModel extends AddonModel<AddonRegistrationData, AddonRegistrationState, 'registration'> {
  parseAddon(data: AddonRegistrationData) {
    const campaignStore = useCampaignStore();
    const { isMobile } = useDevice();
    const state = this.state;
    state.isForm = true;

    state.formId =
      this.getSection().getSectionType() === SectionType.FLOWPAGE
        ? 'form-gameflow-' + this.campaignId
        : 'form-' + this.getSection().id;

    state.campaignId = campaignStore.model?.id;

    state.form = AddonRegistrationModel.parseFormLayoutData(data);
    state.ga = AddonRegistrationModel.parseGAData(data);
    state.redirect = AddonRegistrationModel.parseRedirectData(data);
    state.confirmation = AddonRegistrationModel.parseConfirmationData(data);
    state.button = AddonRegistrationModel.parseButtonData(data);

    state.appearance = AddonRegistrationModel.parseAppearanceDeviceData(data);

    state.disableCookies = data.settings.settings?.disable_cookies === '1';
    state.skipRegistration = data.settings.settings?.skip_registration === '1';

    // disableAutocomplete is true by default
    state.disableAutocomplete =
      data.settings.settings?.disable_autocomplete === undefined
        ? true
        : data.settings.settings.disable_autocomplete === '1';

    const fieldMap: { [key: string]: FormElementModel } = {};
    if (state.fields) {
      state.fields.forEach((field) => {
        fieldMap[field.id] = field;
      });
    }

    if (data.settings.fields) {
      state.fields = data.settings.fields.map((field) => {
        if (field.settings && state.disableCookies) {
          field.settings.disable_cookie = '1';
        }

        if (field.settings && state.disableAutocomplete) {
          field.settings.disable_autocomplete = '1';
        }

        if (field.input_type === FieldInputType.DAWA) {
          state.disableFormAutoComplete = true;
        }

        if (typeof fieldMap[field.id] !== 'undefined') {
          // TODO: Investigate if we can avoid ts-ignore here with other way of typing.
          // @ts-ignore
          fieldMap[field.id].setData(field);
          return fieldMap[field.id];
        }

        if (typeof FormElementMapping[field.input_type] === 'undefined') {
          throw new TypeError(`Unrecognized field input_type: ${field.input_type}`);
        }

        // @ts-ignore
        return new FormElementMapping[field.input_type](field);
      });
    } else {
      state.fields = [];
    }

    if (data.settings?.endpoint) {
      state.endpoint = data.settings.endpoint;
    } else {
      state.endpoint = undefined;
    }

    state.elementStyling = state.elementStyling ?? {};

    if (state.appearance) {
      state.elementStyling.inline = AddonRegistrationModel.constructInlineStyles(state.appearance);
      state.elementStyling.cssStyle = AddonRegistrationModel.constructCssStyle(
        state.appearance,
        this.state.id,
        state.button
      );

      state.elementStyling.buttonContainerStyle = {
        textAlign: state.button ? state.button.alignment : AlignContentType.CENTER
      };
    } else {
      state.elementStyling = {};
    }

    if (state.form && !isMobile) {
      state.elementStyling.container = AddonRegistrationModel.constructContainerStyles(state.form);
    } else {
      state.elementStyling.container = undefined;
    }
  }

  private static parseFormLayoutData(data: AddonRegistrationData): RegistrationFormLayoutState | undefined {
    if (data.settings?.form_layout) {
      return AddonRegistrationModel.constructFormLayoutState(data);
    }
    return undefined;
  }

  private static constructFormLayoutState(data: AddonRegistrationData): RegistrationFormLayoutState | undefined {
    const formlayoutData = data.settings.form_layout;

    if (!formlayoutData) {
      return undefined;
    }

    let formWidth: string | number | undefined;
    let isFormWidthInPixels = false;
    let isFormWidthInPercent = false;

    if (formlayoutData.form_width) {
      isFormWidthInPixels = formlayoutData.form_width.includes('px');
      isFormWidthInPercent = formlayoutData.form_width.includes('%');
      formWidth = transformMeasurementToNumber(formlayoutData.form_width);
    }

    return {
      align: formlayoutData.form_align,
      width: Number(formWidth),
      typeIsPercent: isFormWidthInPercent,
      typeIsPixels: isFormWidthInPixels
    };
  }

  private static parseGAData(data: AddonRegistrationData): GaEvent | undefined {
    if (data.settings?.ga) {
      return AddonRegistrationModel.constructGAState(data);
    }

    return undefined;
  }

  private static constructGAState(data: AddonRegistrationData): GaEvent | undefined {
    const gaData = data.settings.ga;

    if (!gaData) {
      return undefined;
    }

    return {
      action: gaData.ga_event_action || null,
      category: gaData.ga_event_category || null,
      enable: gaData.ga_event_enable === '1',
      label: gaData.ga_event_label || null,
      value: gaData.ga_event_value || null
    };
  }

  private static parseRedirectData(data: AddonRegistrationData): RegistrationRedirectState | undefined {
    if (data.settings.redirect_url || data.settings.redirect_options) {
      return AddonRegistrationModel.constructRedirectState(data);
    }
    return undefined;
  }

  private static constructRedirectState(data: AddonRegistrationData): RegistrationRedirectState {
    const redirectUrl = data.settings.redirect_url || undefined;
    const redirectOptions = data.settings.redirect_options || undefined;

    return {
      url: redirectUrl,
      options: redirectOptions
    };
  }

  private static parseConfirmationData(data: AddonRegistrationData): RegistrationConfirmationState | undefined {
    if (data.settings?.confirmation) {
      return AddonRegistrationModel.constructConfirmationState(data);
    }
    return undefined;
  }

  private static constructConfirmationState(data: AddonRegistrationData): RegistrationConfirmationState | undefined {
    const confirmationData = data.settings.confirmation;

    if (!confirmationData) {
      return undefined;
    }

    return {
      message: confirmationData.message
    };
  }

  private static parseButtonData(data: AddonRegistrationData): RegistrationButtonState | undefined {
    if (data.settings?.button) {
      return AddonRegistrationModel.constructButtonState(data);
    }
    return undefined;
  }

  private static constructButtonState(data: AddonRegistrationData): RegistrationButtonState | undefined {
    const buttonData = data.settings.button;

    if (!buttonData) {
      return undefined;
    }

    return {
      ...(buttonData.background_color && { backgroundColor: buttonData.background_color }),
      ...(buttonData.text_color && { textColor: buttonData.text_color }),
      enabled: buttonData.enabled === '1',
      alignment: buttonData.alignment || AlignContentType.LEFT,
      label: buttonData.label || 'Send',
      type: buttonData.type || ButtonStyleType.PRIMARY
    };
  }

  private static parseAppearanceDeviceData(data: AddonRegistrationData): RegistrationAppearanceDeviceState | undefined {
    if (data.settings.appearance) {
      // default desktop
      let useData: AddonRegistrationAppearanceData | undefined = data.settings.appearance;

      if (useDevice().isMobile) {
        useData = this.getMobileDeviceData(data);
      }

      if (useDevice().isTablet) {
        useData = this.getTabletDeviceData(data);
      }

      if (!useData) {
        return undefined;
      }

      return AddonRegistrationModel.constructAppearanceState(useData);
    }
  }

  public static getTabletDeviceData(data: AddonRegistrationData): AddonRegistrationAppearanceData | undefined {
    const desktopData = data.settings.appearance;

    const { isTablet } = useDevice();

    if (desktopData) {
      const overwriteTablet =
        typeof desktopData.overwrite_tablet === 'undefined' || desktopData.overwrite_tablet === '1';

      if (isTablet && !overwriteTablet && data.settings.appearance?.tablet) {
        // we need to spread over since desktop have some extra data we need
        return { ...desktopData, ...data.settings.appearance.tablet };
      }
    }

    return desktopData;
  }

  public static getMobileDeviceData(data: AddonRegistrationData): AddonRegistrationAppearanceData | undefined {
    const desktopData = data.settings.appearance;

    const { isMobile } = useDevice();

    if (desktopData) {
      const overwriteMobile =
        typeof desktopData.overwrite_mobile === 'undefined' || desktopData.overwrite_mobile === '1';

      if (isMobile && !overwriteMobile && data.settings.appearance?.mobile) {
        // we need to spread over since desktop have some extra data we need
        return { ...desktopData, ...data.settings.appearance.mobile };
      }
    }

    return desktopData;
  }

  private static constructAppearanceDeviceState(
    data: AddonRegistrationAppearanceData | AddonRegistrationAppearanceDevice
  ): RegistrationAppearanceDeviceState {
    const isFontSizeInPixels: boolean = data?.font_size?.includes('px') ?? false;
    const isFontSizeInPercent: boolean = data?.font_size?.includes('%') ?? false;
    let fontSize: number | undefined;

    if (data.font_size !== undefined) {
      fontSize = transformMeasurementToNumber(data.font_size);
    }

    return {
      ...(isValidValue(data.element_space) && { elementSpace: Number(data.element_space) }),
      ...(data.check_color && { checkColor: data.check_color }),
      ...(data.check_background && { checkBackground: data.check_background }),
      ...(data.label_color && { labelColor: data.label_color }),
      ...(data.background_color && { backgroundColor: data.background_color }),
      ...(data.placeholder_color && { placeholderColor: data.placeholder_color }),
      ...(data.stroke_color && { strokeColor: data.stroke_color }),
      ...(data.text_color && { textColor: data.text_color }),
      ...(data.validation_color && { validationColor: data.validation_color }),
      ...(fontSize && { fontSize: Number(fontSize) > 0 ? Number(fontSize) : undefined }),
      ...(isFontSizeInPercent && { fontSizeInPercent: isFontSizeInPercent }),
      ...(isFontSizeInPixels && { fontSizeInPixels: isFontSizeInPixels })
    };
  }

  private static constructAppearanceState(
    data: AddonRegistrationAppearanceData
  ): RegistrationAppearanceState | undefined {
    const appearanceData = data;

    if (!appearanceData) {
      return undefined;
    }

    return {
      formStyle: {
        ...(appearanceData?.form_style?.stroke_color && { strokeColor: appearanceData.form_style.stroke_color }),
        ...(appearanceData?.form_style?.validation_message_type && {
          validationMessageType: appearanceData.form_style.validation_message_type
        }),
        ...(appearanceData?.form_style?.background_color && {
          backgroundColor: appearanceData.form_style.background_color
        })
      },
      ...(appearanceData.input_style && { inputStyle: appearanceData.input_style }),
      ...(isValidValue(appearanceData.element_space) && { elementSpace: Number(appearanceData.element_space) }),
      ...this.constructAppearanceDeviceState(appearanceData)
    };
  }

  private static constructInlineStyles(state: RegistrationAppearanceState): CSSProperties {
    if (!state.formStyle) {
      return {};
    }
    return {
      backgroundColor: state.formStyle.backgroundColor,
      ...(state.formStyle.strokeColor && { border: `1px solid ${state.formStyle.strokeColor}` })
    };
  }

  private static constructContainerStyles(state: RegistrationFormLayoutState): CSSProperties {
    const formAlignment = state.align;
    let formWidth: string | undefined;
    let marginLeft: string | undefined;
    let marginRight: string | undefined;
    if (state.typeIsPixels) {
      formWidth = state.width + 'px';
    }
    if (state.typeIsPercent) {
      formWidth = state.width + '%';
    }

    if (formAlignment === 'center') {
      marginLeft = 'auto';
      marginRight = 'auto';
    } else if (formAlignment === 'left') {
      marginLeft = '0';
      marginRight = 'auto';
    } else if (formAlignment === 'right') {
      marginLeft = 'auto';
      marginRight = '0';
    }

    return {
      width: formWidth,
      ...(marginLeft && marginRight && { margin: `0 ${marginRight} 0 ${marginLeft}` })
    };
  }

  private static constructCssStyle(
    state: RegistrationAppearanceState,
    addonId: string,
    button?: RegistrationButtonState
  ): string {
    const elementStyle: string[] = [];
    const CSSPrefixSection = `.category-landingpage .section .grid__addon--${addonId} `;
    const CSSPrefixPopover = `.category-landingpage .page-modal .grid__addon--${addonId} `;
    const validationColor = state.validationColor && state.validationColor !== '' ? state.validationColor : '#F44336';

    elementStyle.push(`#prefix#.content__item--form .content__item-form-element--date .form-group .form-control {
      padding-left: 0 !important;
      padding-right: 0 !important;
      height: 3.6rem !important;
    }`);

    elementStyle.push(
      `#prefix#.content__item--form .content__item-form-group.form-group--error .content__item-form-label,
      #prefix#.content__item--form .content__item-form-group--select.form-group--error .content__item-form-label,
      #prefix#.content__item--form .content__item-form-group--file.form-group--error .content__item-form-label--file,
      #prefix#.content__item--form .content__item-form-element--checkbox .form-check--error .content__item-form-label,
      #prefix#.content__item--form .content__item-form-element--radio .form-check--error .content__item-form-label {
        color: ${validationColor} !important;
      }`
    );

    elementStyle.push(
      `#prefix#.content__item--form .content__item-form-checkbox.form-check--error .content__item-form-label--checkbox::before,
      #prefix#.content__item--form .content__item-form-checkbox.form-check--error .content__item-form-label--checkbox::after,
      #prefix#.content__item-form-element--captcha .form-group--error .form-control,
      #prefix#.content__item--form .content__item-form-radio.form-check--error .content__item-form-label--radio::after,
      #prefix#.content__item--form .content__item-form-radio.form-check--error .content__item-form-label--radio::before {
        border-color: ${validationColor} !important;
      }`
    );

    elementStyle.push(
      `#prefix#.content__item--form .form-group.content__item-form-group.form-group--error::after,
      #prefix#.content__item--form .content__item-form-group--select.form-group--error::after,
      #prefix#.form-group--error .content__item-form-recaptcha-el iframe {
        background-color: ${validationColor} !important;
        border-color: ${validationColor} !important;
      }`
    );

    elementStyle.push(
      `#prefix#.registration-form--inline-messages .content__item-form-element-message {
        color: ${validationColor} !important;
      }`
    );

    if (state.placeholderColor && state.placeholderColor !== '') {
      if (state.inputStyle && ['columns_rounded', 'columns', 'labels_above'].includes(state.inputStyle)) {
        elementStyle.push(
          `#prefix#.content__item--form .form-control::placeholder,
          #prefix#.content__item--form .form-control::-webkit-input-placeholder {
          color: ${state.placeholderColor} !important;
          opacity: 1 !important;
      }`
        );
      } else {
        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-label,
           #prefix#.content__item-form-group--select .content__item-form-label,
           #prefix#.content__item-form-element--paragraph {
              color: ${state.placeholderColor} !important;
           }`
        );

        elementStyle.push(
          `#prefix#.content__item-form-group--select .vue-select-header input::placeholder,
           #prefix#.content__item--form .content__item-form-mask.form-control::placeholder,
           #prefix#.content__item-form-group--select .content__item-form-select-origin,
           #prefix#.content__item-form-status {
              color: ${state.placeholderColor} !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .form-group--error .content__item-form-label,
           #prefix#.content__item-form-group--select.form-group--error .content__item-form-label,
           #prefix#.content__item-form-group--file.form-group--error .content__item-form-label--file {
             color: ${validationColor} !important;
          }`
        );
      }
    }

    if (state.fontSize) {
      elementStyle.push(
        `#prefix#.content__item--form .form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-checkboxes-label,
        #prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-label--checkbox,
        #prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-label--checkbox p,
        #prefix#.content__item--form .content__item-form-element--radio .content__item-form-label--radio,
        #prefix#.content__item--form .content__item-form-element--radio .content__item-form-radios-label,
        #prefix#.content__item-form-group--select .content__item-form-label--select,
        #prefix#.content__item-form-element--paragraph .content__item-form-group--paragraph,
        #prefix#.content__item-form-element--slider .text-field__label,
        #prefix#.form-group .form-control,
        #prefix#.form-group .content__item-form-textarea,
        #prefix#.content__item-form-group--select .select2-selection__rendered,
        #prefix#.content__item-form-group--select .vue-select-header input,
        #prefix#.content__item-form-group--select .vue-select-header input::placeholder,
        #prefix#.content__item-form-group--select .content__item-form-select-origin,
        #prefix#.content__item-form-filename,
        #prefix#.vue-dropdown,
        #prefix#.vue-dropdown li {
          font-size: ${state.fontSize}${state.fontSizeInPercent ? '%' : 'px'} !important;
        }`
      );
    }

    if (state.backgroundColor && state.backgroundColor !== '') {
      elementStyle.push(
        `#prefix#.content__item--form .form-group .form-control,
        #prefix#.content__item--form .form-group .content__item-file-wrapper,
        #prefix#.content__item--form .form-group .content__item-date-wrapper,
        #prefix#.content__item-form-group--select .select2-selection,
        #prefix#.content__item-form-group--select .vue-select,
        #prefix#.content__item-form-group--select .content__item-form-select-origin {
          background-color: ${state.backgroundColor} !important;
          padding-left: 15px;
          padding-right: 15px;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-checkbox .content__item-form-label--checkbox::after {
          color: ${state.backgroundColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element .content__item-form-group .content__item-form-label:not(.form-check-label) {
        -webkit-transform: translateY(25px) translateX(15px);
        -moz-transform: translateY(25px) translateX(15px);
          transform: translateY(25px) translateX(15px);
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-label {
        -webkit-transform: translateY(30px) translateX(15px);
        -moz-transform: translateY(30px) translateX(15px);
        transform: translateY(30px) translateX(15px);
          opacity: 1;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element .form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element .form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element .form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
        #prefix#.content__item-form-element .content__item-form-group--select.form-group--has-value .content__item-form-label {
          -webkit-transform: scale(0.8) translateY(-9px) translateX(0);
          -moz-transform: scale(0.8) translateY(-9px) translateX(0);
          transform: scale(0.8) translateY(-9px) translateX(0);
        }`
      );
    }

    if (state.strokeColor && state.strokeColor !== '') {
      elementStyle.push(
        `#prefix#.vue-select .arrow-downward {
            border-color:${state.strokeColor} transparent transparent transparent !important;
          }
        `
      );

      elementStyle.push(
        `#prefix#.content__item-form-type--captcha .content__item-form-group .form-control {
          border-color:${state.strokeColor} !important;
        }
        `
      );

      elementStyle.push(
        `#prefix#.content__item-form-type--captcha .captcha-image__countdown-circle {
          stroke: ${state.strokeColor} !important;
        }
        `
      );

      elementStyle.push(
        `#prefix#.content__item-form-type--captcha .captcha-image__countdown-number {
          fill:${state.strokeColor} !important;
        }
        `
      );
    }

    if (state.inputStyle) {
      elementStyle.push(
        `#prefix#.content__item-form-element--text .form-control,
        #prefix#.content__item-form-element--textarea .form-control,
        #prefix#.content__item-form-element--cvr .form-control,
        #prefix#.content__item-form-element--mask .content__item-date-wrapper,
        #prefix#.content__item-form-element--date .content__item-date-wrapper,
        #prefix#.content__item-form-element--typeahead .form-control,
        #prefix#.content__item-form-element--dawa .form-control,
        #prefix#.content__item-form-element--select .vue-select,
        #prefix#.content__item-form-element--file .content__item-file-wrapper{
          border: 1px solid ${state.strokeColor && state.strokeColor !== '' ? state.strokeColor : 'rgba(0,0,0,.12)'};
          padding-left: 15px;
          padding-right: 15px;
          height: 4rem;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--mask .form-group .content__item-date-wrapper,
        #prefix#.content__item--form .content__item-form-element--date .form-group .content__item-date-wrapper {
          height: 4rem;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item-form-element--text .form-group--error .form-control,
        #prefix#.content__item-form-element--textarea .form-group--error .form-control,
        #prefix#.content__item-form-element--cvr .form-group--error .form-control,
        #prefix#.content__item-form-element--typeahead .form-group--error .form-control,
        #prefix#.content__item-form-element--captcha .form-group--error .form-control,
        #prefix#.content__item-form-element--dawa .form-group--error .form-control,
        #prefix#.content__item-form-element--date .form-group--error .content__item-date-wrapper,
        #prefix#.content__item-form-element--mask .form-group--error .content__item-date-wrapper,
        #prefix#.content__item-form-element--file .form-group--error .content__item-file-wrapper,
        #prefix#.content__item-form-group--select.form-group--error .vue-select,
        #prefix#.content__item-form-group--select.form-group--error .content__item-form-select-origin,
        #prefix#.form-group--error .content__item-form-recaptcha-el iframe {
          border-color: ${validationColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-label {
          position: absolute;
          top: -2.1rem;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-label--checkbox::before {
          border-color: ${
            state.strokeColor && state.strokeColor !== '' ? state.strokeColor : 'rgba(0,0,0,.12)'
          } !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--radio .content__item-form-label--radio:after, #prefix#.content__item--form .content__item-form-element--radio .content__item-form-label--radio:before {
          color: ${state.strokeColor && state.strokeColor !== '' ? state.strokeColor : 'rgba(0,0,0,.12)'} !important;
          border-color: ${
            state.strokeColor && state.strokeColor !== '' ? state.strokeColor : 'rgba(0,0,0,.12)'
          } !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item-form-radio.form-check--error .content__item-form-label--radio:after, #prefix#.content__item-form-radio.form-check--valid .content__item-form-label--radio:before {
          color: ${validationColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.form-group.content__item-form-group::before,
        #prefix#.form-group.content__item-form-group::after {
          display: none;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--cvr .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--mask .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--date .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--file .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--typeahead .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element.content__item-form-element--dawa .content__item-form-group .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element .content__item-form-group--select .content__item-form-label {
          -webkit-transform: translateY(25px) translateX(15px);
          -moz-transform: translateY(25px) translateX(15px);
          transform: translateY(25px) translateX(15px);
          opacity: 1;
          -webkit-transition: none;
          transition: none;
          line-height: 3.8rem;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element-message {
          margin-top: 0.4rem !important;
        }`
      );

      elementStyle.push(
        `#prefix#.registration-form--inline-messages .content__item-form-element-message {
          margin-top: 0 !important;
        }`
      );

      if (state.inputStyle === InputStyleType.CLASSIC || state.inputStyle === InputStyleType.CLASSIC_ROUNDED) {
        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element .form-group:before,
          #prefix#.content__item--form .content__item-form-element .form-group:after {
            display: none;
          }`
        );
      }

      if (
        state.inputStyle !== InputStyleType.COLUMNS &&
        state.inputStyle !== InputStyleType.COLUMNS_ROUNDED &&
        state.inputStyle !== InputStyleType.LABELS_ABOVE
      ) {
        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .form-group--has-value .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .form-group--has-value .content__item-form-label,
          prefix#.content__item--form .content__item-form-element.content__item-form-element--cvr .form-group--has-value .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--date .form-group--has-value .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--file .form-group--has-value .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--typeahead .form-group--has-value .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--dawa .form-group--has-value .content__item-form-label,
          #prefix#.content__item-form-element .content__item-form-group--select.form-group--has-value .content__item-form-label {
            display: none;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element .form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
            visibility: hidden;
            opacity: 0;
            -webkit-transition: none;
            transition: none;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element--file .form-group:before,
          #prefix#.content__item--form .content__item-form-element--file .form-group:after {
            display: none;
          }`
        );
      }

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--text .form-group,
        #prefix#.content__item--form .content__item-form-element--cvr .form-group,
        #prefix#.content__item--form .content__item-form-element--mask .form-group,
        #prefix#.content__item--form .content__item-form-element--date .form-group,
        #prefix#.content__item--form .content__item-form-element--file .form-group,
        #prefix#.content__item--form .content__item-form-element--typeahead .form-group,
        #prefix#.content__item--form .content__item-form-element--dawa .form-group,
        #prefix#.content__item--form .content__item-form-element--select .form-group,
        #prefix#.content__item--form .content__item-form-element--paragraph .form-group {
          padding-bottom: 0;
        }`
      );

      if (state.inputStyle === InputStyleType.CLASSIC_ROUNDED || state.inputStyle === InputStyleType.COLUMNS_ROUNDED) {
        elementStyle.push(
          `#prefix#.content__item--form .form-group .form-control,
          #prefix#.content__item--form .form-group .content__item-file-wrapper,
          #prefix#.content__item--form .form-group .content__item-date-wrapper,
          #prefix#.content__item-form-group--select .select2-selection,
          #prefix#.content__item-form-group--select .vue-select,
          #prefix#.content__item-form-group--select .content__item-form-select-origin {
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element-message {
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
          }`
        );
      }

      if (state.inputStyle === InputStyleType.COLUMNS || state.inputStyle === InputStyleType.COLUMNS_ROUNDED) {
        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element--text .form-group .form-control,
          #prefix#.content__item--form .content__item-form-element--mask .form-group .content__item-date-wrapper,
          #prefix#.content__item--form .content__item-form-element--textarea .form-group .form-control,
          #prefix#.content__item--form .content__item-form-element--cvr .form-group .form-control,
          #prefix#.content__item--form .content__item-form-element--date .form-group .content__item-date-wrapper,
          #prefix#.content__item--form .content__item-form-element--file .form-group .content__item-file-wrapper,
          #prefix#.content__item--form .content__item-form-element--typeahead .form-group .form-control,
          #prefix#.content__item--form .content__item-form-element--dawa .form-group .form-control,
          #prefix#.content__item-form-group--select .select2-selection,
          #prefix#.content__item-form-group--select .vue-select,
          #prefix#.content__item-form-group--select .content__item-form-select-origin {
            margin-left: 15rem;
            width: -webkit-calc(100% - 15rem);
            width: -moz-calc(100% - 15rem);
            width: calc(100% - 15rem);
            height: 3.8rem;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element.content__item-form-element--text .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--mask .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--textarea .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--date .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--file .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--cvr .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--typeahead .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--dawa .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--select .content__item-form-group .content__item-form-label {
            -webkit-transform: translateY(24px);
            -moz-transform: translateY(24px);
            transform: translateY(24px);
            width: 14rem;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element.content__item-form-element--date .content__item-form-group .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .content__item-form-group--select.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
            -webkit-transform: translateY(24px) scale(1) !important;
            -moz-transform: translateY(24px) scale(1) !important;
            transform: translateY(24px) scale(1) !important;
            opacity: 1 !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element .content__item-form-type--phone-code .content__item-form-group .content__item-form-label {\
            left: 0 !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element-message {
            max-width: calc(100% - 18rem) !important;
            left: 18rem !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-type--phone-code .content__item-form-group--select {
            margin-left: 15rem !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-type--phone-code .content__item-form-group--select {
            position: absolute;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-type--phone-code .content__item-form-group--text {
            width: 100%;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-type--phone-code .content__item-form-group--select .select2-selection,
          #prefix#.content__item--form .content__item-form-type--phone-code .content__item-form-group--select .content__item-form-select-origin {
            margin-left: 0 !important;
            width: 100% !important;
          }`
        );

        elementStyle.push(
          `.category-desktop#prefix#.content__item--form .content__item-form-type--phone-code .form-group .content__item-form-text {
            width: calc(100% - 26rem) !important;
            margin-left: 26rem !important;
          }`
        );

        elementStyle.push(
          `.category-mobile#prefix#.content__item--form .content__item-form-type--phone-code .form-group .content__item-form-text {
            width: calc(100% -15rem)!important;
            margin-left: 15rem !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element .form-group:before,
          #prefix#.content__item--form .content__item-form-element .form-group:after {
            display: none;
          }`
        );
      }

      if (state.inputStyle === InputStyleType.LABELS_ABOVE) {
        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element--text .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--textarea .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--cvr .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--mask .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--date .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--file .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--typeahead .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--dawa .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element--select .content__item-form-label {
            position:static !important;
            -webkit-transform: translateY(0px) translateX(0px) !important;
            -moz-transform: translateY(0px) translateX(0px) !important;
            transform: translateY(0px) translateX(0px) !important;
            opacity: 1 !important;
            height: auto;
            line-height: 3.6rem !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-active:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--is-focus:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .content__item-form-group.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label,
          #prefix#.content__item--form .content__item-form-element .content__item-form-group--select.form-group--has-value:not(.form-group--fixed-label) .content__item-form-label {
            -webkit-transform: translateY(0px) translateX(0px) !important;
            -moz-transform: translateY(0px) translateX(0px) !important;
            transform: translateY(0px) translateX(0px) !important;
            opacity: 1 !important;
            visibility: visible !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .content__item-form-element-message {
            top: 7.5rem !important;
          }`
        );

        elementStyle.push(
          `#prefix#.content__item--form .form-element-type--file .form-group:before,
          #prefix#.content-type-form .form-element-type--file .form-group:after {
            display: none;
          }`
        );
      }
    }

    if (state.elementSpace) {
      state.elementSpace = isValidValue(state.elementSpace) ? Number(state.elementSpace) : 25;
      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element {
            margin-bottom:${Number(state.elementSpace)}px;
         }
        `
      );
    }

    if (state.textColor && state.textColor !== '') {
      elementStyle.push(
        `#prefix#.content__item--form .form-group .form-control,
        #prefix#.content__item-form-group--select .select2-selection,
        #prefix#.content__item-form-group--select .vue-input input,
        #prefix#.content__item-form-group--select .vue-select-header input,
        #prefix#.content__item-form-group--select.form-group--has-value .vue-select-header input::placeholder,
        #prefix#.content__item-form-group--select .content__item-form-select-origin,
        #prefix#.content__item-form-status {
            color: ${state.textColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content-type-form .form-element .text-field .text-field__input,
        #prefix#.content-type-form .input-file .input-file__wrapper,
          .category-mobile#prefix#.dropdown-mob__item {
          color: ${state.textColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content-type-form .form-element-radio label {
          color: ${state.textColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content-type-form .form-element-checkbox .checkbox__label {
          color: ${state.textColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .form-element__message {
          color: ${state.textColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix .vue-select-header .icon.arrow-downward {
            border-color: ${state.textColor} transparent;
        }`
      );
    }

    if (state.strokeColor && state.strokeColor !== '') {
      elementStyle.push(
        `#prefix#.form-group.content__item-form-group::before,
          #prefix#.content__item-form-group--select::before,
          #prefix#.content__item-form-group--border {
            border-color: ${state.strokeColor};
        }
        `
      );

      elementStyle.push(
        `#prefix#.content__item-form-radio .content__item-form-label--radio::after {
          color: ${state.textColor};
        }`
      );

      elementStyle.push(
        `#prefix#.form-group.content__item-form-group::after,
        #prefix#.content__item-form-group--select::after,
        #prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-checkbox-input:checked + .content__item-form-label--checkbox::after {
          background-color: ${state.strokeColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.form-group.content__item-form-group.form-group--error::after,
          #prefix#.content__item-form-group--select.form-group--error::after,
          #prefix#.form-group--error .content__item-form-recaptcha-el iframe {
          background-color: ${validationColor} !important;
          border-color: ${validationColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item-form-checkbox .content__item-form-label--checkbox::before,
          #prefix#.content__item-form-checkbox .content__item-form-label--checkbox::after,
          #prefix#.content__item-form-radio .content__item-form-label--radio::after,
          #prefix#.content__item-form-radio .content__item-form-label--radio::before {
          border-color: ${state.strokeColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item-form-checkbox.form-check--error .content__item-form-label--checkbox::before,
        #prefix#.content__item-form-checkbox.form-check--error .content__item-form-label--checkbox::after,
        #prefix#.content__item-form-type--captcha .content__item-form-group.form-group--error .form-control,
        #prefix#.content__item-form-radio.form-check--error .content__item-form-label--radio::after,
        #prefix#.content__item-form-radio.form-check--error .content__item-form-label--radio::before {
          border-color: ${validationColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix# .vue-select-header .arrow-downward {
          border-color: ${state.strokeColor} transparent transparent transparent !important;
        }`
      );
    }

    if (state.labelColor && state.labelColor !== '') {
      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element--radio .content__item-form-label,
        #prefix#.content__item-form-checkboxes-label,
        #prefix#.content__item-form-element--file .content__item-form-label,
        #prefix#.content__item--form .content__item-form-element--radio .content__item-form-radios-label,
        #prefix#.content__item-form-element--paragraph  {
          color: ${state.labelColor} !important;
        }`
      );

      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--checkbox .form-check--error .content__item-form-label,
        #prefix#.content__item-form-element--radio .form-check--error .content__item-form-label {
          color: ${validationColor} !important;
        }`
      );
    }

    if (state.checkColor && state.checkColor !== '') {
      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-checkbox .content__item-form-label--checkbox::after,
        #prefix#.content__item--form .content__item-form-radio .content__item-form-label--radio::after {
          color: ${state.checkColor} !important;
        }`
      );
    }

    if (state.checkBackground && state.checkBackground !== '') {
      elementStyle.push(
        `#prefix#.content__item--form .content__item-form-element--checkbox .content__item-form-checkbox-input:checked + .content__item-form-label--checkbox::after {
          background-color: ${state.checkBackground} !important;
        }`
      );
    }

    if (button && button.enabled) {
      if (button.backgroundColor && button.backgroundColor !== '') {
        elementStyle.push(
          `#prefix#.content__item--action .content__item-action {
             background-color: ${button.backgroundColor} !important;
          }`
        );
      }

      if (button.textColor && button.textColor !== '') {
        elementStyle.push(
          `#prefix#.content__item--action .content__item-action {
            color: ${button.textColor} !important;
          }`
        );
      }
    }

    const styleRules: string[] = [];

    if (elementStyle && elementStyle.length > 0) {
      for (const styleRule in elementStyle) {
        styleRules.push(elementStyle[Number(styleRule)].replace(/#prefix#/gi, CSSPrefixSection));
        styleRules.push(elementStyle[Number(styleRule)].replace(/#prefix#/gi, CSSPrefixPopover));
      }
    }

    return styleRules.join('');
  }

  async onDelete(): Promise<void> {
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/onAddonRemove/${this.campaignId}/${
      this.getSection().id
    }?row_index=${this.column?.row.index}&col_index=${this.column?.index}&addon_index=${this.index}`;

    const { deleteData } = useAxios(postUrl);

    await deleteData();
    await super.onDelete();
  }

  public isAddonValid(): boolean {
    if (this.state && this.state.fields && this.state.fields.length > 0) {
      return true;
    }
    return false;
  }

  canDuplicate(): boolean {
    return false;
  }

  canPaste(): boolean {
    return false;
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }

  public setValue(path: string, value: string | number | boolean, shouldParse?: boolean) {
    if (path.startsWith('appearance.input_style')) {
      const data = this.getData();

      if (data.settings.appearance) {
        // default 25
        data.settings.appearance.element_space = 25;

        if (data.settings.appearance.mobile) {
          data.settings.appearance.mobile.element_space = 25;
        }
      }
    }

    if (path.startsWith('appearance.overwrite_mobile')) {
      // reset spacing
      const data = this.getData();

      if (data.settings.appearance?.mobile) {
        data.settings.appearance.mobile.element_space = 25;
      }
    }

    if (path.startsWith('appearance.overwrite_tablet')) {
      // reset spacing
      const data = this.getData();

      if (data.settings.appearance?.tablet) {
        data.settings.appearance.tablet.element_space = 25;
      }
    }

    if (path.startsWith('settings.')) {
      const data = this.getData();

      if (!data.settings.settings) {
        data.settings.settings = {};
      }

      switch (path) {
        case 'settings.disable_autocomplete':
          data.settings.settings.disable_autocomplete = String(value);
          break;
        case 'settings.disable_cookies':
          data.settings.settings.disable_cookies = String(value);
          break;
        case 'settings.skip_registration':
          data.settings.settings.skip_registration = String(value);
          break;
      }

      this.setData(data);

      if (shouldParse) {
        // Re-run the parsing after changing values in the original data
        this.parse(this.getData());
      }

      return;
    }

    super.setValue(path, value, shouldParse);
  }
}
