import type { ApiMessageData } from '../api';
import type { SectionData } from '@/src/typings/interfaces/data/section';
import type { AlignContentType, CookieConsentProvider } from '@/src/typings/enums/enums';
import type { BaseIntegrationData } from '@/src/models/integrations/BaseIntegration';
import type { DeviceData } from '@/src/hooks/useDevice';

export enum MessageTypes {
  CHEATING = 'cheating',
  EXPIRED = 'expired',
  EXPIRED_CAMPAIGN = 'expired_campaign',
  GAME_LIMIT = 'game_limit',
  PAUSED_CAMPAIGN = 'paused_campaign'
}

export enum MessageDisplayTypes {
  REDIRECT = 'redirect',
  FLOWPAGE = 'flowpage',
  INLINE_MESSAGE = 'inline_message'
}

export interface AddonEditItem {
  name: string;
  description: string;
  icon: string;
  category: string;
  area: string;
}

interface CustomFontData {
  id: number;
  css: string;
  fontFamily: string;
}

export interface CampaignConfigData {
  campaign_id: number;
  customer_id: number;
  title: string;
  name: string;
  lead_score_enabled: boolean;
  game_type: string;
  game_alias: string;
  game_icon: string;

  time?: {
    unix: number;
    formatted: string;
  };

  custom_solutions?: {
    id: string;
    environment: 'local' | 'dev' | 'staging' | 'production';

    assets: {
      js: boolean;
      css: boolean;
    };

    settings: unknown;
  }[];

  campaign_api_root?: string;
  asset_url: string;
  favicon?: string;
  api_endpoint?: string;

  edit: {
    enabled: boolean;
    csrf_token?: string;
    addons?: { [key: string]: AddonEditItem };
  };

  ipstack_api?: string;
  google_maps_api_key?: string;
  language_code: string;
  timezone: string;
  active_from?: string;
  active_to?: string;
  global_layout: boolean;
  gtm_id?: string;
  enable_only_allow_viewing_in_iframe: boolean;
  enable_statistics?: boolean;
  enable_zoom?: boolean;
  expired: boolean;
  upload_proxy?: string;
  ghost_user: boolean;
  deactivate_internal_link_tracking: boolean;
  utm_enabled: boolean;
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  utm_content: string;
  custom_fonts: CustomFontData[];
  deactivate_ga?: number;

  has_registrations: boolean;
  has_leaderboard?: boolean;

  hash: string;
  cookies_enabled?: boolean;
  cookie_consent?: {
    provider: CookieConsentProvider;
  };

  user_recognition?: {
    mapping: {
      [key: string]: number;
    };

    parameters: string[];
    messages: {
      [key: string]: CampaignMessageData;
    };

    allowUnrecognisedUsers: boolean;
    waitForTokens: boolean;
  };

  integrations: BaseIntegrationData[];

  resources: {
    before_body_close?: string;
    before_body_start?: string;
    css?: string;
    head?: string;
  };

  fb_app_id: string;
  fb_verification_id: string;
  meta_tags: MetaTag[];

  logo?: DeviceData<ConfigLogoData>;
  replacementtags?: {
    [key: string]: unknown;
  };

  device_type?: 'ALL_DEVICES' | 'MOBILE_ONLY' | 'ADS';
  ad_dimensions?: string[];
  ad_size_type?: 'RESPONSIVE' | 'FIXED';
}

export interface ConfigLogoData {
  src: string;
}

interface MetaTag {
  [key: string]: string;
}

export type CampaignViewApiResponse = CampaignData & {
  message?: ApiMessageData;
  redirect?: {
    target: string;
    url: string;
  };
};

export interface CampaignRedirectData {
  type: '_blank' | '_self' | '_top';
  url: string;
}

export interface CampaignData {
  advanced: CampaignAdvancedData;
  config: CampaignConfigData;
  sections: SectionData[];
  global_layout?: DeviceData<CampaignLayoutData>;
  layout?: DeviceData<CampaignLayoutData>;
  pages?: {
    flow?: {
      after: [];
      all: SectionData[];
      before: [];
    };
    pages: SectionData[];
    redirect?: CampaignRedirectData;
  };
}
interface CampaignCSSData {
  css?: string;
}

interface ExternalCSSData {
  name?: string;
  url?: string;
}

interface JavascriptData {
  javascript: string;
}

export interface CampaignAdvancedData {
  settings: CampaignSettingsData;
  css: CampaignCSSData;
  externalcss: ExternalCSSData;
  javascript: JavascriptData;
  [key: string]: CampaignSettingsData | CampaignMessageData | CampaignCSSData | ExternalCSSData | JavascriptData;
}

interface CampaignSettingsData {
  preloader?: CampaignPreloaderData;
  favicon?: string;
  retention?: CampaignRetentionData;
  control_code: CampaignControlCodeData;
}

export interface CampaignMessageData {
  description?: string;
  headline: string;
  message: MessageTypes;
  redirect_target?: '_blank' | '_self' | '_top';
  display_type?: MessageDisplayTypes;
  redirect_url?: string;
  flow_page?: string;
  inline_styles?: string;
}

interface CampaignPreloaderData {
  type: string;
  color: string;
  effect?: string;
  image?: string;
  keep_size?: string;
  overlay: OverlayData;
}

interface OverlayData {
  enabled: string;
  color: string;
}

interface CampaignRetentionData {
  timing: string;
}

interface CampaignControlCodeData {
  enabled: string;
  label: string;
  type: 'bottom' | 'top' | 'overlay' | 'replacementtag';
  background_color: string;
  text_color: string;
}

export interface CampaignLayoutData {
  base_color?: string;
  background_color?: string;
  text_color?: string;
  button_text_color?: string;
  background_image?: string;
  background_type?: string;
  background_attachment_fixed?: string;
  background_position: string;
  logo?: string;
  background_overlay: string;
  font_family?: string;
  button_style?: string;
  flow?: CampaignLayoutFlowData;
  custom_popover_colors?: string;
  popover_background_color?: string;
  popover_text_color?: string;
  overwrite?: string;
  scroll_indicator?: CampaignLayoutScrollIndicatorData;
  styles?: StylesData;
  grid?: DeviceData<GridData>;
  buttons?: CampaignLayoutButtonsData;
  focus?: {
    color: string;
    width: string;
  };
}

export interface StylesData {
  [key: string]: StyleElementData;
}

export interface StyleElementData {
  element: string;
  type: string;
  size: string;
  color?: string;
  line_height: string;
  weight: string;
  style?: string[];
}

interface CampaignLayoutFlowData {
  transition?: string;
  overwrite?: string;
  overlay?: string;
}

interface CampaignLayoutScrollIndicatorData {
  enable?: string;
  color?: string;
}

export interface CampaignLayoutButtonsData {
  add_shadow: string;
  background_color: string;
  text_color: string;
  vertical_padding: string;
  horisontal_padding: string;
  font_family: string;
  text_size: string;
  text_weight: string;
  letter_spacing?: string;
  border_color?: string;
  border_thickness?: string;
  border_radius?: string;
  line_height?: string;
  button_width?: string;
  background_color_hover?: string;
  text_color_hover?: string;
  border_color_hover?: string;
  button_effect?: string;
  overwrite?: string;
  text_style?: string[];
  hover_text_style?: string[];
  shadow_blur: string;
  shadow_color: string;
  shadow_horizontal: string;
  shadow_spread: string;
  shadow_vertical: string;
  secondary?: ButtonSecondaryData;
}

interface GridData {
  pixel_size?: string;
  align?: AlignContentType;
  type_pixel?: string;
  type_percent?: string;
  percent_size?: string;
}

interface ButtonSecondaryData {
  add_shadow: string;
  background_color?: string;
  text_color?: string;
  vertical_padding?: string;
  horisontal_padding?: string;
  font_family?: string;
  text_size?: string;
  text_weight?: string;
  letter_spacing?: string;
  border_color?: string;
  border_thickness?: string;
  border_radius?: string;
  line_height?: string;
  button_width?: string;
  background_color_hover?: string;
  text_color_hover?: string;
  text_style?: string[];
  hover_text_style?: string[];
  border_color_hover?: string;
  button_effect?: string;
  shadow_blur?: string;
  shadow_color?: string;
  shadow_horizontal?: string;
  shadow_spread?: string;
  shadow_vertical?: string;
  overwrite?: string;
}
