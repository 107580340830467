import type {
  GamePriorityState,
  PriorityAnswerButton,
  PriorityAnswerValidation,
  PriorityCustomWidth,
  PriorityDropzoneItem,
  PriorityDropzonePlacement,
  PriorityDropzoneSettings,
  PriorityDropzoneSize,
  PriorityDropzoneType,
  PriorityGeneralSettings,
  PriorityImageOrder,
  PriorityItem
} from './typing';
import {
  PriorityAlignmentEnum,
  PriorityDropzonePlacementType,
  PriorityFontElementEnum,
  PriorityItemSizeUnit,
  PriorityItemType
} from './typing';
import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import type {
  GamePriorityData,
  GamePriorityGeneralData,
  PriorityGeneralCustomData,
  PriorityGeneralCustomItemData,
  PriorityGeneralLayoutData,
  PriorityImageItemData,
  PriorityPlaceholdersItemData
} from '@/src/components/games/priority/Data';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { GameIndicatorIcon, GameIndicatorPositionType } from '@/src/components/indicators/Model';
import { generateUniqueId, randomize, shuffle } from '@/src/utilities/Utilities';

export class GamePriorityModel extends GameModel<GamePriorityData, GamePriorityState> implements HasGameTimeChallenge {
  parseGame(data: GamePriorityData) {
    const state = this.state;

    state.general = GamePriorityModel.constructGeneralSettings(data);

    state.dropzones = [];
    state.priorityItems = [];

    const customData = GamePriorityModel.parseGeneralCustomDeviceData(data.general);

    let placeholderType: PriorityDropzoneType;

    if (data.images.add_placeholder_image === '1') {
      placeholderType = 'image';
    } else if (data.images.add_placeholder_value === '1') {
      placeholderType = 'label';
    } else {
      placeholderType = 'none';
    }

    for (let index = 0; index < Number(data.general.number_of_placeholders); index++) {
      // casting for SAST
      index = Number(index);

      let placeholderLabel = (index + 1).toString();
      let placeholderImage: string | undefined;
      let item: PriorityDropzoneItem | undefined;
      const allowedAnswerIds: number[] = [];

      const activeId = generateUniqueId();

      if (placeholderType === 'label') {
        if (data.images.map_to_placeholder === '1' && data.images.placeholder) {
          placeholderLabel = data.images.placeholder[Number(index)]?.label ?? '';
        } else {
          placeholderLabel = data.images.priority[Number(index)].placeholder_value ?? (index + 1).toString();
        }
      }

      if (data.images.placeholder && placeholderType === 'image') {
        placeholderImage = data.images.placeholder[Number(index)]?.label ?? '';
      }

      data.images.priority.forEach((prioItems, itemIndex) => {
        if (state.general.mapToPlaceholder && prioItems.placeholders?.includes(index)) {
          allowedAnswerIds.push(Number(prioItems.id));
        } else if (!state.general.mapToPlaceholder && itemIndex === index) {
          allowedAnswerIds.push(Number(prioItems.id));
        }
      });

      const labels: PriorityPlaceholdersItemData = {
        label: placeholderLabel,
        ...(data.images.placeholder && { group_label: data.images.placeholder[Number(index)]?.group_label ?? '' })
      };

      if (data.general.display === 'default') {
        item = GamePriorityModel.constructPlaceholderItem(
          data.general,
          activeId,
          placeholderType,
          labels,
          placeholderImage,
          allowedAnswerIds
        );
      }
      if (data.general.display === 'custom' && customData) {
        item = GamePriorityModel.constructPlaceholderCustomItem(
          data.general,
          activeId,
          customData?.placement[Number(index)],
          placeholderType,
          labels,
          placeholderImage,
          allowedAnswerIds
        );
      }
      if (item) {
        state.dropzones.push(item);
      }
    }

    for (let index = 0; index < data.images.priority.length; index++) {
      if (data.images.priority && data.images.priority[Number(index)]) {
        state.priorityItems.push(
          GamePriorityModel.constructPriorityItem(data.general, data.images.priority[Number(index)])
        );
      }
    }

    if (data.general.random_order === '1') {
      shuffle(state.priorityItems);
    }
  }

  private static constructPriorityItem(data: GamePriorityGeneralData, image: PriorityImageItemData): PriorityItem {
    const layout = GamePriorityModel.parseGeneralLayoutDeviceData(data);

    const customWidth: PriorityCustomWidth = {
      enabled: !!layout?.image_width
    };

    if (layout?.image_width?.includes('px')) {
      customWidth.type = 'pixel';
      customWidth.value = parseInt(layout?.image_width, 10);
    } else if (layout?.image_width?.includes('%')) {
      customWidth.type = 'percent';
      customWidth.value = parseInt(layout?.image_width, 10);
    }

    return {
      id: image.id ? Number(image.id) : randomize(1, 100000),
      image: image.image ? image.image : '',
      zIndex: Math.floor(Math.random() * 25) + 1, // nosem: ajinabraham.njsscan.crypto_node.node_insecure_random_generator
      customWidth,
      initialSize: {
        ...(customWidth.value && { width: customWidth.value })
      },
      isPlaced: false
    };
  }

  private static parseGeneralLayoutDeviceData(data: GamePriorityGeneralData): PriorityGeneralLayoutData | undefined {
    if (data.layout) {
      const useData = getDeviceData<PriorityGeneralLayoutData>(data.layout);

      if (!useData) {
        return undefined;
      }
      return useData;
    }
  }

  private static parseGeneralCustomDeviceData(data: GamePriorityGeneralData): PriorityGeneralCustomData | undefined {
    if (data.custom) {
      const useData = getDeviceData<PriorityGeneralCustomData>(data.custom);

      if (!useData) {
        return undefined;
      }
      return useData;
    }
  }

  private static constructGeneralSettings(data: GamePriorityData): PriorityGeneralSettings {
    const answerButton: PriorityAnswerButton = {
      enabled: false,
      label: 'answer'
    };

    if (data.general.show_answer_button === '1') {
      answerButton.enabled = true;

      if (data.general.answer_btn) {
        answerButton.label = data.general.answer_btn;
      }
    }

    const fixedImageOrder: PriorityImageOrder = {
      enabled: false
    };
    if (data.general.image_position === 'fixed') {
      fixedImageOrder.enabled = true;
      fixedImageOrder.shuffled = data.general.random_order === '1';
    }

    const dropzone: PriorityDropzoneSettings = {
      allowMultipleImages: data.images.multiple_in_placeholder === '1',
      alignment: PriorityAlignmentEnum.CENTER,
      customPlacement: {
        enabled: false
      },
      layoutType: PriorityItemType.SQUARE,
      styling: {
        border: {
          enabled: false
        },
        font: {
          enabled: false
        }
      },

      answer: {
        type: data.images.multiple_in_placeholder === '1' ? 'multiple' : 'default',
        hide: data.images.remove_image_in_placeholder === '1',
        showCorrect: false
      },
      rows: 1
    };

    if (data.general.show_correct_priority === '1' && data.images.multiple_in_placeholder !== '1') {
      dropzone.answer.showCorrect = true;
    }

    if (data.general.layout) {
      const layout = GamePriorityModel.parseGeneralLayoutDeviceData(data.general);
      const customBg = GamePriorityModel.parseGeneralCustomDeviceData(data.general)?.bg;

      if (layout?.placeholder.rows) {
        dropzone.rows = Number(layout?.placeholder.rows);
      }

      if (data.general.display === 'custom') {
        dropzone.customPlacement.enabled = true;
        dropzone.customPlacement.background = customBg;
      }

      switch (layout?.placeholder.alignment) {
        case 'left':
          dropzone.alignment = PriorityAlignmentEnum.LEFT;
          break;
        case 'right':
          dropzone.alignment = PriorityAlignmentEnum.RIGHT;
          break;
      }
      if (layout?.placeholder.design === 'circle') {
        dropzone.layoutType = PriorityItemType.CIRCLE;
      }

      if (layout?.placeholder) {
        dropzone.styling.border.enabled = !!layout.placeholder.border_color;

        if (layout.placeholder.border_thickness && layout.placeholder.border_thickness !== '') {
          dropzone.styling.border.thickness = Number(layout.placeholder.border_thickness);
        }
        if (layout.placeholder.border_color) {
          dropzone.styling.border.color = layout.placeholder.border_color;
        }
        if (layout.placeholder.background?.color) {
          dropzone.styling.backgroundColor = layout.placeholder.background?.color;
        }

        if (layout.placeholder.width) {
          dropzone.styling.width = {
            enabled: true
          };
          dropzone.styling.width.value = Number(layout.placeholder.width);
        }
        if (layout.placeholder.height) {
          dropzone.styling.height = Number(layout.placeholder.height);
        }
        if (layout?.placeholder_width) {
          dropzone.styling.width = {
            enabled: true
          };
          if (layout?.placeholder_width?.includes('px')) {
            dropzone.styling.width.type = 'pixel';
            dropzone.styling.width.value = parseInt(layout?.placeholder_width, 10);
          } else if (layout?.placeholder_width?.includes('%')) {
            dropzone.styling.width.type = 'percent';
            dropzone.styling.width.value = parseInt(layout?.placeholder_width, 10);
          }
          // height is ONLY in pixels in settings.
          if (layout?.placeholder_height) {
            dropzone.styling.height = parseInt(layout?.placeholder_height, 10);
          }
        }

        if (layout?.placeholder?.font || data.images.add_placeholder_image === '0') {
          dropzone.styling.font.enabled = true;
          if (layout.placeholder.font?.color) {
            dropzone.styling.font.color = layout.placeholder.font.color;
          }

          switch (layout.placeholder.font?.style) {
            case 'h1':
              dropzone.styling.font.element = PriorityFontElementEnum.H1;
              break;
            case 'h3':
              dropzone.styling.font.element = PriorityFontElementEnum.H3;
              break;
            case 'h4':
              dropzone.styling.font.element = PriorityFontElementEnum.H4;
              break;
            case 'h5':
              dropzone.styling.font.element = PriorityFontElementEnum.H5;
              break;
            case 'h6':
              dropzone.styling.font.element = PriorityFontElementEnum.H6;
              break;
            default:
              dropzone.styling.font.element = PriorityFontElementEnum.H2;
              break;
          }
        }
      }
    }

    return {
      mapToPlaceholder: data.images.map_to_placeholder === '1',
      // new setting - force true here for undefined and '1' since older campaigns can have it undefined (false value will change how the game works)
      removePlaceholderImage: data.images.remove_placeholder_image !== '0',
      answerButton,
      fixedImageOrder,
      dropzone,
      groupByDropzone: data.images.group_images === '1'
    };
  }

  private static constructPlaceholderCustomItem(
    general: GamePriorityGeneralData,
    priorityId: string,
    dropzonePlacement: PriorityGeneralCustomItemData,
    dropzoneType: PriorityDropzoneType,
    labels?: PriorityPlaceholdersItemData,
    image?: string,
    allowedAnswerIds?: number[]
  ): PriorityDropzoneItem {
    const layout = GamePriorityModel.parseGeneralLayoutDeviceData(general);

    const placement: PriorityDropzonePlacement = {
      type: PriorityDropzonePlacementType.CUSTOM,
      height: Number(dropzonePlacement.h),
      yAxis: Number(dropzonePlacement.y),
      xAxis: Number(dropzonePlacement.x),
      width: Number(dropzonePlacement.w)
    };

    let type = PriorityItemType.SQUARE;

    if (layout?.placeholder.design === 'circle') {
      type = PriorityItemType.CIRCLE;
    }

    const size: PriorityDropzoneSize = {
      enabled: false
    };

    if (layout?.placeholder.width || layout?.placeholder.height) {
      size.enabled = true;
      size.unit = PriorityItemSizeUnit.PIXEL;
      if (layout.placeholder.height) {
        size.height = parseInt(layout.placeholder.height, 10);
      }
      if (layout.placeholder.width) {
        size.width = parseInt(layout.placeholder.width, 10);
      }
    }

    const validation: PriorityAnswerValidation = {
      enabled: false,
      isValidating: false,
      isCorrect: false
    };

    if (general.show_correct_priority) {
      validation.enabled = true;
    }

    return {
      placement,
      type,
      size,
      validation,
      ...(labels?.label && { label: labels.label }),
      ...(labels?.label && { groupLabel: labels.group_label }),
      id: priorityId,
      priorityItems: [],
      ...(image && { image }),
      ...(allowedAnswerIds && allowedAnswerIds.length > 0 && { allowedAnswerIds }),
      dropzoneType
    };
  }

  private static constructPlaceholderItem(
    general: GamePriorityGeneralData,
    id: string,
    dropzoneType: PriorityDropzoneType,
    labels?: PriorityPlaceholdersItemData,
    image?: string,
    allowedAnswerIds?: number[]
  ): PriorityDropzoneItem {
    const layout = GamePriorityModel.parseGeneralLayoutDeviceData(general);

    let type = PriorityItemType.SQUARE;

    if (layout?.placeholder.design === 'circle') {
      type = PriorityItemType.CIRCLE;
    }

    const placement: PriorityDropzonePlacement = {
      type: PriorityDropzonePlacementType.DEFAULT
    };

    const size: PriorityDropzoneSize = {
      enabled: false
    };

    if (layout?.placeholder.width || layout?.placeholder.height) {
      size.enabled = true;
      size.unit = PriorityItemSizeUnit.PIXEL;
      if (layout.placeholder.height) {
        size.height = parseInt(layout.placeholder.height, 10);
      }
      if (layout.placeholder.width) {
        size.width = parseInt(layout.placeholder.width, 10);
      }
    }

    const validation: PriorityAnswerValidation = {
      enabled: false,
      isValidating: false,
      isCorrect: false
    };

    if (general.show_correct_priority) {
      validation.enabled = true;
    }

    return {
      placement,
      type,
      size,
      validation,
      ...(labels?.label && { label: labels.label }),
      ...(labels?.label && { groupLabel: labels.group_label }),
      id,
      priorityItems: [],
      ...(image && { image }),
      ...(allowedAnswerIds && allowedAnswerIds.length > 0 && { allowedAnswerIds }),
      dropzoneType
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_left: 'timeleft',
          time_used: 'timeused'
        },
        icon: GameIndicatorIcon.TIME,

        value: {
          time_left: this.state.timeChallenge?.limit ?? 60,
          time_used: 0
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
